.verifyExistingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;

    .verifyForm {
        width: 100%;
        max-width: 400px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .titleForm h1 {
            font-size: 2rem;
            text-align: center;
        }

        .inputAnimated {
            width: 100%;
            margin-bottom: 15px;
            padding: 10px;
            font-size: 1rem;
        }

        .buttonForm {
            display: flex;
            justify-content: center;

            .button {
                background-color: #007BFF;
                border: none;
                color: white;
                padding: 10px 20px;
                text-align: center;
                text-decoration: none;
                display: inline-block;
                font-size: 1rem;
                margin: 10px 0;
                cursor: pointer;
                border-radius: 5px;
                transition: background-color 0.3s ease;

                &:hover {
                    background-color: #0056b3;
                }
            }
            .disabledButton{
                background-color: #757575;
            }
        }
        .bottomForm{
            display: flex;
            justify-content: center;
            span{
                text-decoration: none;
            }
        }
    }

    /* Ajustes para pantallas pequeñas */
    @media (max-width: 600px) {
        .verifyForm {
            max-width: 100%;
            padding: 0 10px;
        }

        .titleForm h1 {
            font-size: 1.5rem;
        }

        .inputAnimated {
            font-size: 0.9rem;
            padding: 8px;
        }

        .button {
            font-size: 0.9rem;
            padding: 8px 15px;
        }
    }
}