.carousel-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 20px; // Añade un poco de espacio alrededor del carrusel
  flex-wrap: wrap; // Permite que los elementos se envuelvan en pantallas más pequeñas
}

.carousel-container {
  flex: 0 0 70%; // Haz que el contenedor del carrusel ocupe el 70% del ancho del contenedor padre
  max-width: 950px; // Limita el ancho máximo de la imagen principal
  min-width: 300px; // Limita el ancho mínimo del contenedor para pantallas pequeñas
}

.thumbnail-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 20px;

  .thumbnail {
    width: 75px; // Ajusta el tamaño de las miniaturas
    height: 40px;
    margin: 10px 0;
    cursor: pointer;
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.1);
    }

    &.active {
      border: 2px solid #333; // Estilo para resaltar la miniatura activa
    }
  }
}

.carousel .slide img {
  width: 100%;
  height: auto;
}

.carousel .carousel-slider .control-arrow {
  background: rgba(0, 0, 0, 0.5);
}

.carousel .control-dots {
  display: none;
}

.carousel-title {
  width: 100%;
  text-align: left;
  padding: 10px 20px;
  font-size: 25pt;
  box-sizing: border-box;
  font-family: "Source Sans Pro", sans-serif;
  text-shadow: -3px 3px #ffffff, -3px -3px #ffffff, 3px -3px #ffffff, 3px 3px #f3f3f2, 6px 6px #B5B0B0;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 70%; // Ajusta esto para controlar la longitud del efecto de desenfoque
    background: inherit; // Para heredar el fondo y la transparencia del elemento principal
    filter: blur(5px); // Ajusta el nivel de desenfoque aquí
    z-index: -1; // Asegura que el pseudo-elemento esté detrás del texto
  }
}

@media (max-width: 768px) {
  .carousel-container {
    flex: 0 0 100%; // Ocupa el 100% del ancho en pantallas más pequeñas
  }

  .thumbnail-container {
    margin-left: 0;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    .thumbnail {
      width: 60px; // Ajusta el tamaño de las miniaturas para pantallas más pequeñas
      height: 34px;
      margin: 5px;
    }
  }
  .carousel-title {
    width: 100%;
    text-align: left;
    padding: 10px 20px;
    font-size: 15pt;
    box-sizing: border-box;
  }
  
}

@media (max-width: 480px) {
  .carousel-container {
    flex: 0 0 100%;
  }

  .thumbnail-container {
    .thumbnail {
      width: 50px; // Ajusta el tamaño de las miniaturas para pantallas muy pequeñas
      height: 28px;
    }
  }
}