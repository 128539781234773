.error404Container {
    display: flex;
    justify-content: center;
    
  img{
    width: 100%;
    
  }
}

@media screen and (max-width: 1024px){
    .error404Container {
        display: flex;
        justify-content: center;
        
      img{
        margin-bottom: 25px;
      }
    }
}
@media screen and (max-width: 768px){
    .error404Container {
        display: flex;
        justify-content: center;
        
      img{
        margin-bottom: 0px;
        height: 700px;
      }
    }
}
@media screen and (max-width: 425px){
    .error404Container {
        display: flex;
        justify-content: center;
        
      img{
        margin-bottom: 0px;
        height: 400px;
      }
    }
}