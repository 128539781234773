@import 'src/_variables.scss';
.title-1{
    font-size: 24pt;
    font-weight: 400;
    font-family: $fonttype;
    height: fit-content;
    margin: 0;
}
.title-2 {
    font-size: $caption;
    font-weight: 600;
    color: #5375f1;

}
.title-3 {
    font-size: $title;
    font-weight: 400;
    color: $lightgray;
    margin: 5px 0px;
}