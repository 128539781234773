@import 'src/_variables.scss';

.edit-teacher-class {
    width: 90vw;
    max-width: 1200px;
    padding: 20px 20px;
    .required{
        border: 1px solid red;
    }
    .header {

        .checks-inp {
            display: flex;
            gap: 15px;
            justify-content: flex-end;

            div {
                width: fit-content;
            }
        }
    }

    .content {
        display: flex;
        gap: 2%;
        flex-wrap: wrap;

        .section {
            width: 49%;
            min-width: 300px;
            flex-grow: 1;
        }

        .age-range {
            h1 {
                width: 100%;
            }

            .age-inputs {
                display: flex;
                gap: 2%;

                div {
                    width: 49%;
                }
            }
        }

        .date-time {
            h1 {
                width: 100%;
            }
            .class-days{
                display:flex;
                width: fit-content;
                gap: 25px;
                padding: 5px;
                .checkbox_days{
                    display: flex;
                    flex-direction: column-reverse;
                    justify-content: center;
                    align-items: center;
                    margin: 0;
                }
            }
            .time-inputs {
                display: flex;
                gap: 2%;
                margin-top: 10px;

                div {
                    width: 32%;
                }
            }
        }

        .notes {
            width: 100%;

            textarea {
                width: 99%;
                height: 200px;
                font-size: 1.5em;
                background-color: #aaaaaa80;
            }
        }

        .students-qty {
            margin-top: 10px;
            display: flex;
            gap: 10px;
        }

        .unit-parts {
            display: flex;
            margin-top: 5px;
            gap: 15px;
            justify-content: flex-end;

            div {
                width: fit-content;
            }
        }
    }

    .footer {
        margin-top: 15px;
        display: flex;
        gap: 5px;

        .delete-section {
            width:50%
        }

        .confirm-buttons-section {
            width:50%;
            display: flex;
            gap: 5px;
            justify-content: flex-end;
        }
    }

}