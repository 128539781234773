@import 'src/_variables.scss';

.recep-main {
    display: flex;
    margin-top: 60px;
    min-height: 95vh;
    height: fit-content;
    font-family: $fonttype;
    width:100%;
    /* This can change, it depends of the father container*/
    .recep-side-menu {
        position: relative;
        display: flex;
        width: fit-content;
        height: 100%;
        z-index: 1;

        .menu {
            width: 350px;
        }

        .side-menu-bar {
            display: none;
            width: 15px;
            height: 100%;
            background: rgba(54, 54, 54, 0.4);
            align-items: center;
            justify-content: center;
            cursor: pointer;
            letter-spacing: -1px;
        }
    }


    .recep-contain {        
        display: flex;
        flex-grow: 1;
        min-height: 100%;
        padding: 2%;
    }
}

@media screen and (max-width:1150px) {
    .recep-main {
        .recep-side-menu {
            position: absolute;

            .menu {
                margin-left: -350px;
                overflow: hidden;

            }

            .active {
                margin-left: 0px;
                transition: all ease .2s;

            }

            .side-menu-bar {
                display: flex;
                color: white;
            }
        }

        .recep-contain {
            width: 100%;
            padding-left: 15px;

        }
    }
}