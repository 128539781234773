@import 'src/_variables.scss';

.main-album-container {
    margin: 25px 25px 60px 25px;
    display: flex;
    flex-direction: column;
    gap: 25px;

    h2 {
        width: 100%;
        font-size: 25pt;
        box-sizing: border-box;
        font-family: "Source Sans Pro", sans-serif;
        text-shadow: -3px 3px #ffffff, -3px -3px #ffffff, 3px -3px #ffffff, 3px 3px #f3f3f2, 6px 6px #B5B0B0;
        position: relative;
        
    }
    .albumTitle0{
        color:$button
    }
    .albumTitle1{
        color:#842B97
    }
    .albumTitle2{
        color:#00904D
    }

    .photo-container {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(min(300px, 100%), 350px));
        gap: 5vw;
        justify-content: center;
        overflow: hidden;


        img {
            width: 100%;
            height: auto;
            box-sizing: border-box;
        }

        @media (min-width: 1200px) {
            grid-template-columns: repeat(4, 1fr); // 4 columnas
        }

        @media (min-width: 900px) and (max-width: 1199px) {
            grid-template-columns: repeat(4, 1fr); // 4 columnas

        }

        @media (min-width: 600px) and (max-width: 899px) {
            grid-template-columns: repeat(2, 1fr); // 2 columnas

           
        }

        @media (max-width: 425px) {
            grid-template-columns: repeat(auto-fill, minmax(min(300px, 100%), 350px));
        }
    }
}
