@import '../../variables';

.functionNav {

    .divFunctionButton {
        position: relative;
        margin: 0px 0px 0px 5px;

        .functionButton {
            height: 30px;
            min-height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            // background-color: transparent;
            border: 0px;
            padding: 3px;
            border-radius: $modalborders;

            &.transparent {
                background-color: transparent;

            }

            &.red {
                background-color: $sistemred;
            }

            &.blue {
                background-color: $activeblue;
            }

            &.gray {
                background-color: $gray;
            }

            &.lightgray {
                background-color: $lightgray;
            }


            .buttonIcon {
                display: flex;
                align-items: center;

                img {
                    width: 20px;
                    margin-right: 3px;
                }

            }

            .buttonText {
                font-family: $fonttype;
                font-size: $normal;
                color: white;
                letter-spacing: 1px;
                padding: 2px;
                text-wrap: nowrap;
            }


        }
    }

    .hoverButton {
        :hover {
            backdrop-filter: brightness(0.9);
            cursor: pointer;
        }
    }
}

@media screen and (max-width: 800px) {
    .divFunctionButton {
        .functionButton {

            .buttonText {
                letter-spacing: normal !important;
                text-wrap: wrap !important;
                font-size: 10pt !important;
            }


        }
    }
}

@media screen and (max-width: 600px) {
    .divFunctionButton {
        .functionButton {
            height: auto !important;

            .buttonText {
                letter-spacing: normal !important;
                text-wrap: wrap !important;
            }


        }
    }
}

@media screen and (max-width: 600px) {
    .divFunctionButton {
        .functionButton {

            .buttonText {
                display: none;
            }

            .buttonIcon {
                img {
                    margin: 0px 3px 0px 3px !important;
                }

            }

        }
    }
}