@import 'src/_variables.scss';

.custom-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    color: white !important;
    cursor: pointer;
    transition: background-color 0.3s ease;
    &.blue {
        background-color: $button;
    }

    &.red {
        background-color: $activered;
    }
    &:disabled {
        background-color: $dissablegray;
        cursor: not-allowed;
    }
    &:hover{
        filter:brightness(.8)
    }

    .button-icon {
        margin-right: 8px;
        width: 20px;
        height: 20px;
    }

    .button-text {
        display: inline-block;
        color: #fff !important;
        text-align: center;

    }
}
