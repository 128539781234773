@import 'src/_variables.scss';


.student-register {
    width: 90%;
    height: fit-content;
    padding: max(2%, 10px);
    border-radius: 10px;
    box-sizing: border-box;

    .register-header {
        display: flex;
        justify-content: space-between;

        h1 {
            font-size: 24pt;
            font-weight: 400;
            font-family: $fonttype;
            height: fit-content;
            margin: 0;
        }

        .close-button {
            background: transparent;
            border: none;
            font-size: $title;
            font-family: calibri;
            font-weight: 200;
            height: fit-content;

            svg {
                background-color: transparent;
            }
        }
    }

    .register-contain {
        display: flex;
        flex-wrap: wrap;
        gap: 2%;
        width: 100%;

        .section {
            width: 25%;
            min-width: 300px;
            max-width: 600px;
            height: fit-content;
            display: flex;
            flex-wrap: wrap;
            flex-grow: 1;
            .empty{
                border: 1px solid red;
            }
            .title {
                font-size: $caption;
                font-weight: 600;
                color: #5375f1;
                margin: 15px 0px;

            }

            .input-container {
                margin-top: 10px;
            }
        }


        .data-personal {
            display: block;
            width: 100%;
            max-width: none;

            .personal-contain {
                display: flex;
                flex-wrap: wrap;
                gap: 2%;
                min-width: 300px;
                align-items: flex-end;

                .picture-name {
                    width: 69%;
                    display: flex;
                    flex-wrap: wrap;
                    gap: 5%;
                    justify-content: left;
                    flex-grow: 1;
                    height: fit-content;

                    .data-name {
                        flex-grow: 1;
                        height: fit-content;
                    }

                    .picture {
                        width: 120px;
                        height: 150px;
                        margin-bottom: 10px;

                        div {
                            height: 100%;

                            input {
                                height: 100%;
                                padding: 0%;
                                // background-image: url('../../../Image/user.png');
                                background-size: contain;
                                background-repeat: no-repeat;
                                background-position-y: center;
                            }
                        }
                    }
                }

                .personal-other {
                    width: 29%;
                    min-width: 300px;
                    flex-grow: 1;
                }
            }
        }

        .data-contact {
            .contact-phone{
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                gap: 2%;
                div{
                    flex-grow: 1;
                    width: 49%;
                }
            }
            .street-num {
                display: flex;
                width: 100%;
                gap: 2%;

                div:nth-child(1) {
                    width: 40%;
                }

                div:nth-child(2) {
                    width: 30%;
                }
                div:nth-child(3) {
                    width: 30%;
                }
            }

            .cp-city {
                display: flex;
                width: 100%;
                gap: 2%;

                div:nth-child(1) {
                    width: 70%;
                }

                div:nth-child(2) {
                    width: 28%;
                }
            }
            .guardianRequired{
                display: flex;
                gap: 5px;
                margin-top: 5px;
            }
        }

        .data-school {
            width: 25%;
            min-width: 300px;
            max-width: 600px;
            height: fit-content;
            display: flex;
            flex-wrap: wrap;
            flex-grow: 1;

            .title {
                font-size: $caption;
                font-weight: 600;
                color: #5375f1;
                margin: 15px 0px;

            }
            .empty{
                border: 1px solid red;
            }
        }

        .data-enroll {
            .notes {
                margin-top: 20px;
                width: 99%;
                height: fit-content;

                textarea {
                    height: 200px;
                    width: 100%;
                    background-color: transparent;
                    border: 1px solid #b5b0b0;

                    &::placeholder {

                        color: #b5b0b0;
                    }
                }

                .sign {
                    display: flex;
                    justify-content: flex-end;
                    gap: 15px;
                }
            }
        }
    }

    .register-footer {
        margin-top: 20px;
        display: flex;
        justify-content: flex-end;

        .buttons {
            display: flex;
            justify-content: flex-end;
            width: 80%;
            gap: 15px;
        }
    }
}

@media screen and (max-width:1440px) {
    .student-register {
        width: 100%;
    }
}

@media screen and (max-width:425px) {
    .student-register {
        min-width: 320px;
    }
}