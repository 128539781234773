.chooseAuthenticationContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 165px);
  font-family: "Montserrat", sans-serif;
  font-size: 12pt;
  .chooseAuthenticationContainerBorder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
    img {
      min-width: calc(10% - 150px);
    }
    h1 {
      color: #f85656;
      text-align: center;
    }
    p {
      font-weight: bolder;
      text-align: center;
      width: calc(40% - 10px);
      a {
        color: #0000ee;
        &:hover {
          cursor: pointer;
          text-decoration: underline;
          transition: ease-in-out 2ms;
        }
      }
    }
    .chooseAuthenticationSelectOption {
      display: flex;
      flex-direction: column;
      gap: 15px;

      .optionInput {
        font-weight: bold;
      }
      .buttonSubmit {
        background-color: rgb(248, 86, 86);
        color: #fff;
        padding: 10px;
        font-size: 15px;
        transition: 0.2s ease-in-out;
        border: 2px solid rgb(248, 86, 86);
        font-weight: bold;

        border-radius: 5px;

        &:hover {
          background-color: transparent;
          border: 2px solid #000;
          color: rgb(248, 86, 86);
          cursor: pointer;
        }
      }
    }
    .message {
      color: #f85656;
      margin-bottom: 25px;
    }
  }
}
