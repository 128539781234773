
////
@import '../../variables';


.adult_side_left {
  background-color: $darkgray;
  width: 20%;
  min-width: 320px;
  z-index: 1000;
  .nav {
    .nav_conteiner {
      .nav_menu {
        display: none;
      }

      .dropdown {
        // display: grid;
        background-color: $darkgray;
        width: 100%;
        left: 0;
        top: 0;
        padding: 0;
        margin: 0;

        .dropdown_header {
          height: 100%;
          color: $lightergray;
          padding: 5%;
          background-color: $gray;
        }

        .dropdown_list {
          list-style: none;

          .dropdown_link {
            display: flex;
            position: relative;
            padding: 5%;
            text-decoration: none;
            color: white;
            align-items: center;
            gap: .6rem;
            transition: .2s all;

            .dropdown_check {
              position: absolute;
              width: 100%;
              height: 100%;
              opacity: 0;
              cursor: pointer;
              z-index: 10000;
            }

            .dropdown_arrow {
              margin-left: auto;
              transform: var(--rotate, 0);
              transition: .4s transform;
            }

          }

          .disabled {
            // display: none !important;
            transform: scaleY(0);
            height: 0;
            padding: 0;
            margin: 0;
          }

          &:has(:checked) {
            --rows: 1fr;
            --rotate: rotate(180deg);
          }

          .dropdown_content {
            display: grid;
            grid-template-rows: var(--rows, 0fr)0fr;
            transition: .3s grid-template-rows;

            .dropdown_sub {
              overflow: hidden;
              padding-left: 0;

              .dropdown_sub_li {
                list-style: none;
                background-color: var(--bg, transparent);

                .dropdown_anchor {
                  position: relative;
                  display: flex;
                  text-decoration: none;
                  width: 100%;
                  height: 50px;
                  align-items: center;

                  .dropdown_anchor_span {
                    padding: 0.6em 0;
                    display: relative;
                    color: white;
                    margin-left: 10%;
                    font-size: 2vh;
                    text-decoration: none;
                  }

                  .dropdown_anchor_check {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    opacity: 0;
                    cursor: pointer;
                    z-index: 10000;
                  }

                  &:hover {
                    cursor: pointer;
                    background: #8d866473;
                  }

                  .back_arrow {
                    display: var(--display, none);
                    position: relative;
                    height: 50%;
                  }
                }

                .dropdown_sub_li_content {
                  display: grid;
                  grid-template-rows: var(--rows2, 0fr)0fr;
                  transition: .3s grid-template-rows;

                  .drop_unit {
                    list-style: none;
                    overflow: hidden;
                    padding-left: 0;

                    .drop_unit_li {
                      // display: flex;
                      padding: 2% 10%;
                      // align-items: center;
                      color: white;
                      // list-style: none;

                      i {
                        height: 3vh;
                        margin-right: 15px;
                      }

                      .dropdown_sub_li_span {
                        font-size: 1.5vh;
                      }

                      .drop_unit_anchor {
                        display: flex;
                        position: relative;
                        // padding: 5%;
                        text-decoration: none;
                        color: white;
                        align-items: center;
                        gap: 0.6rem;
                        transition: .2s all;

                        img {
                          margin-right: 5px;
                        }

                        .dropdown_anchor_unit_span {
                          width: 90%;
                          font-size: 1.5vh;
                          color: white;
                          margin-left: 0;
                        }

                        .drop_unit_anchor_check {
                          position: absolute;
                          width: 100%;
                          height: 100%;
                          opacity: 0;
                          cursor: pointer;
                          z-index: 10000;
                        }

                        .drop_unit_arrow {
                          margin-left: auto;
                          transform: var(--rotate2, 0);
                          transition: .4s transform;
                        }

                      }

                      .drop_unit_li_content {
                        display: grid;
                        grid-template-rows: var(--rows3, 0fr) 0fr;
                        transition: .3s grid-template-rows;

                        .drop_unit_li_sub {
                          overflow: hidden;
                          padding-left: 0;

                          .drop_unit_li_sub_li {
                            list-style: none;
                            padding-left: 40%;

                          }
                        }
                      }

                      &:has(:checked) {
                        --rows3: 1fr;
                        --rotate2: scaleY(-1);
                      }

                      &:hover{
                        cursor: pointer;
                        // color:#25252473
                      }
                    }
                  }
                }

                &:has(:checked) {
                  --rows2: 1fr;
                  --display: flex;
                  --bg: #25252473;
                }


              }
            }
          }
        }

        .yellow {
          .dropdown_link {
            color: $yellow;
            border-bottom: solid 1px $yellow;

            .dropdown_arrow {
              color: $yellow;
            }
          }

          .dropdown_content {
            background-color: #E9C62D;

            .dropdown_sub {
              .dropdown_sub_li {
                .dropdown_sub_li_content {
                  .drop_unit {
                    background-color: #E9C62D;

                  }
                }

              }
            }
          }
        }

        .red {
          .dropdown_link {
            color: $sistemred;
            border-bottom: solid 1px $sistemred;

            .dropdown_arrow {
              color: $sistemred;
            }
          }

          .dropdown_content {
            background-color: #a70b0b;
          }
        }

        .blue {
          .dropdown_link {
            color: #7CC4F8;
            border-bottom: solid 1px #7CC4F8;

            .dropdown_arrow {
              color: $lightbue;
            }
          }

          .dropdown_content {
            background-color: $lightbue;
          }
        }

        .green {
          .dropdown_link {
            color: $green;
            border-bottom: solid 1px $green;

            .dropdown_arrow {
              color: $green;
            }
          }

          .dropdown_content {
            background-color: $green;
          }
        }

        .purple {
          .dropdown_link {
            color: #c080fc;
            border-bottom: solid 1px #c080fc;

            .dropdown_arrow {
              color: #c080fc;
            }
          }

          .dropdown_content {
            background-color: $purple;
          }
        }

      }
    }
  }
}



@media screen and (max-width:1200px) and (min-width:901px) {
  .adult_side_left {
    width: 30%;
    min-width: 268px;
  }

}

@media screen and (max-width:900px) and (min-width:601px) {
  .adult_side_left {
    width: 30%;
    min-width: 200px;
  }

}

@media screen and (max-width:600px) {
  .adult_side_left {
    position: absolute;
    background-color: transparent;
    transition: clip-path .5s;
    width: 40px;
    height: 5%;
    top: 0;
    float: right;
    margin-top: 60px;

    .nav {
      width: var(--width, 0);
      height: var(--height, 0);
      background-color: $darkgray;
      --img1: scale(1);
      --img2: scale(0);

      .nav_conteiner {
        margin: 0 auto;
        align-items: center;
        overflow: hidden;
        .nav_conteiner_icons_menu{
          display: flex;
          width: 100vw;
          position: absolute;
          top: 115px;
          .nav_menu {
            display: flex;
            position: absolute;
            margin: 5px;
            padding: 0;
            z-index: 10000;
            transform: var(--img1);
            top: var(--top,0);
            // left: var(--left,0);
            right: var(--right);
            text-decoration: none;
            color: $lightgray;
          }
          .nav_start{
            top: -100px;
          }
          .nav_menu--second {
            transform: var(--img2)
          }
  
          .dropdown {
            width: 100vw;
            clip-path: var(--clip, polygon(0 0, 0 0, 0 0, 0 0));
          }
        }


      }

      &:has(.dropdown:target) {
        --img1: scale(0);
        --img2: scale(1);
        --clip: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
        --width: 100vw;
        --height: 100vh;
        --top: -13vh;
        --right: 2vw;
        --left: auto;
      }
    }

  }



}