/*This are the styles of component Alerta*/
/**************************************************/

/*Alerta conteiner box*/
.divAlert {
    //---Dimensions---
    width: 25%;
    font-family: Calibri, sans-serif;
    position: fixed;
    bottom: 5%;
    left: 75%;
    z-index: 10000; // Alingment

    .alert {
        width: 100%;
        margin: 5px;

        .alertbox {
            height: max-content;
            border-radius: 5px;
            position: relative;
            display: flex;
            justify-content: left;
            align-items: center;
            transition: all 0.5s;

            /*Icon*/
            .icon {
                width: auto;
                padding: 2%;

                img {
                    width: 30px;
                }
            }

            /*Message*/
            .message {
                margin: 1%;
                height: auto;
                width: 80%;
                font-size: 10pt;
                text-align: left;
                padding-right: 10%;
            }

            /*Close Button*/
            .closeButton {
                width: 8%;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 92%;
                border-radius: 0px 5px 5px 0px;

                button {
                    width: 100%;
                    height: 100%;
                    background: none;
                    font-size: 14pt;
                    font-weight: bold;
                    font-stretch: expanded;
                    border: 0px;
                    text-align: center;
                }
            }
        }

        /*Type of success (GREEN)*/
        .success {
            background-color: lightgreen;
            color: green;
            border-left: 3px solid green;

            .closeButton {
                background-color: rgb(110, 210, 110);

                button {
                    color: rgb(50, 179, 50);
                }

                &:hover {
                    background-color: rgb(81, 164, 81);

                    button {
                        cursor: pointer;
                        color: rgb(28, 100, 28);
                    }
                }
            }
        }

        /*Type of warning (YELLOW)*/
        .warning {
            background-color: rgb(255, 255, 158);
            color: rgb(255, 191, 0);
            border-left: 3px solid rgb(255, 191, 0);

            .closeButton {
                background-color: rgb(250, 214, 104);

                button {
                    color: rgb(255, 191, 0);
                }

                &:hover {
                    background-color: rgb(207, 172, 68);

                    button {
                        cursor: pointer;
                        color: rgb(149, 119, 27);
                    }
                }
            }
        }

        /*Type of info (BLUE)*/
        .info {
            background-color: lightblue;
            color: blue;
            border-left: 3px solid blue;

            .closeButton {
                background-color: rgb(101, 202, 236);

                button {
                    color: rgb(30, 143, 181);
                }

                &:hover {
                    background-color: rgb(60, 159, 192);

                    button {
                        cursor: pointer;
                        color: rgb(11, 90, 116);
                    }
                }
            }
        }

        /*Type of danger (RED)*/
        .danger {
            background-color: lightsalmon;
            color: red;
            border-left: 3px solid red;

            .closeButton {
                background-color: rgb(230, 100, 100);

                button {
                    color: rgb(215, 69, 69);
                }

                &:hover {
                    background-color: rgb(189, 84, 84);

                    button {
                        cursor: pointer;
                        color: rgb(122, 29, 29);
                    }
                }
            }
        }
    }

    .is-open {
        margin-top: 2%;
        animation-name: opening;
        animation-duration: 1s;
        animation-timing-function: ease-out;

    }
}
@keyframes opening {
    from {
        transform: translateY(100px);
        opacity: 0;
    }

    to {
        transform: translateY(0px);
        opacity: 1;
    }
}

@media screen and (max-width: 1200px) {
    .divAlert {
        //---Dimensions---
        width: 30%;
        left: 69%;
    }
}

@media screen and (max-width: 900px) {
    .divAlert {
        //---Dimensions---
        width: 40%;
        left: 59%;
        bottom: 1%;
    }
}

@media screen and (max-width: 700px) {
    .divAlert {
        //---Dimensions---
        width: 70%;
        left: 15%;
        bottom: 0%;
    }
}

@media screen and (max-width: 400px) {
    .divAlert {
        //---Dimensions---
        width: 100%;
        left: 0%;
        bottom: 0%;
    }
}