@import 'src/_variables.scss';

.test_container {
    width: 100%;
    font-family: $fontthin;
    font-size: $normalInfo;
    min-height: calc(100vh - 345px);

    .tst_header {
        position: relative;
        display: flex;
        justify-content: center;
        color: white;
        font-weight: 300;
        padding: 0;
        height: 500px;

        .tst_header_img {
            position: relative;
            width: 100%;
            height: 100%;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            div {
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: rgba(255, 0, 0, 0.15);
                top: 0;
            }
        }

        .tst_header_text {
            position: absolute;
            top: 50%;
            margin: 0;
        }

        .tst_header_title1 {
            position: absolute;
            font-size: 65pt;
            right: 5%;
            bottom: 100px;
            margin: 0;
            color: #D8130E;
            font-weight: normal;
        }

        .tst_header_title2 {
            position: absolute;
            font-size: 80pt;
            right: 5%;
            bottom: 0%;
            margin: 0;
            font-weight: normal;
        }
    }

    .paragraph {
        position: relative;
        text-align: center;
        width: 80%;
        margin-left: 10%;

        .caption1 {
            font-weight: 100;
            margin: 0;
            color: #1338BD;
        }

        .caption2 {
            font-weight: 100;
            margin: 0;
            color: #1338BD;

        }

        span {
            margin-bottom: 50px;
        }
        .buttonContainer{
            width: 100%;
            display: flex;
            justify-content: center;
            margin-top: 25px;
            margin-bottom: 25px;
            .button {
                display: flex;
                padding: 10px;
                background-color: $activered;
                width: 150px;
                border: 0;
                border-radius: 5px;
                justify-content: center;
                align-items: center;
                height: 25px;
    
                a {
                    font-size: $normal;
                    font-family: $fontthin;
                    color: white;
                    text-decoration: none;
                }
    
                &:hover {
                    opacity: .8;
                    transform: scale(105%);
                    transition: all .2s;
                    cursor: pointer;
                }
            }
        }
    }

    img {
        width: 100%;
    }
}

@supports (-webkit-touch-callout: none) {
    @media screen and (max-width:1080px) {
        .test_container {
            
            .paragraph {
                font-size: 16pt;
                .button {
                    display: flex;
                    width: 30%;
                    height: 35px;
                    align-items: center;

                    a {
                        font-size: $caption;

                    }


                }
            }
        }
    }
}

@media screen and (max-width:768px) {
    .test_container {
        font-size: 1.3rem !important;

        .tst_header {
            height: 250px;

            .tst_header_title1 {
                font-size: 1.8em;
                bottom: 25%;
            }

            .tst_header_title2 {
                font-size: 4rem;

            }
        }
    }
}

@media screen and (max-width:500px) {
    .test_container {
        .tst_header {
            .tst_header_title1 {
                right: 5%;
            }

            .tst_header_title2 {
                position: absolute;
                right: 5%;
                bottom: 0%;
                margin: 0;

            }
        }

        .paragraph {


            .caption2 {
                font-weight: 100;
                margin: 0;
                color: #1338BD;

            }

            
        }

        img {
            width: 100%;
        }
    }
}