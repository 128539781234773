@import 'src/_variables.scss';

.student-info {
    position: relative;
    width: 95%;
    height: fit-content;
    border-radius: 10px;

    .info-header {
        display: flex;
        justify-content: space-between;
        h1 {
            font-size: 24pt;
            font-weight: 400;
            font-family: $fonttype;
            height: fit-content;
            margin: 0;
        }
        .close-button{
            background: transparent;
            border: none;
            font-size: $title;
            font-family: calibri;
            font-weight: 200;
            height: fit-content;
            cursor:pointer;
            svg{
                background-color: transparent;
            }
        }
    }
    .info-searching {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-between;

        .search-box {
            width: 35%;
            min-width: 300px;
            position: relative;
            color: #757575;
            border-radius: 5px;
            outline: none;
            border: 1px solid #B5B0B0;
            box-sizing: border-box;
            font-size: $caption;

            &:focus {
                border-color: #007bff;
                outline: none;
            }
        }
        .name-box{
            width: 60%;
            min-width: 280px;
            position: relative;
            padding: 10px;
            outline: none;
            box-sizing: border-box;
            color: $button ;
            font-size: $title;
            font-weight:400;
            background-color: transparent !important;
            border: 0;
        }
    }
    .info-contain{
        margin-top: 20px;
    }
}

@media screen and (max-width:1440px) {
    .student-info {
        width: 100%;
    }
}