
.MuiPaper-root{    
    position: relative;
    background-color: transparent;
    overflow: hidden;
}
.modalForm {
    position: relative;
    min-height: 400px;
    max-height: 90%;
    width: 50%;
    border-radius: 7px;
    margin: 0px;
    padding: 3%;
    background-color: #FFFF;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    overflow-y: scroll;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 13.5pt;
    letter-spacing: 0.6pt;
    justify-content: center;

    /*-------------------------------------------------------------------------------------------------------*/
    /*Scroll bar*/
    /*-------------------------------------------------------------------------------------------------------*/
    &::-webkit-scrollbar {
        width: var(--scroll-size, 10px);
        height: var(--scroll-size, 10px);
    }

    &::-webkit-scrollbar-track {
        background-color: var(--scroll-track, transparent);
        border-radius: var(--scroll-track-radius, var(--scroll-radius));
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--scroll-thumb-color, grey);
        background-image: var(--scroll-thumb, none);
        border-radius: var(--scroll-thumb-radius, var(--scroll-radius));
        --scroll-size: 10px;
        --scroll-track: rgb(255 255 255 / 10%);
        --scroll-thumb: linear-gradient(45deg, #FFFF, #8c8b8b, #5c5c5c, #8c8b8b, #FFFF);
    }

    .formClass {
        box-sizing: border-box;
        height: 100%;

        .formHeader {
            display: flex;
            padding: 10px;
            justify-content: space-between;

            h1 {
                font-size: 20pt;
                text-align: left;
                position: relative;
            }
        }

        .formBody {
            display: flex;
            padding: 10px;
            flex-direction: column;
            gap: 15px;

            .unitEditContainer{
                select{
                    width: -webkit-fill-available;
                }
                .checkPartContainer{
                    display: flex;
                    justify-content: flex-end;
                    gap: 5px;
                }
            }
            .day_off_container{
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 5px;
                label{
                    font-size: 8pt;
                }
            }
            select {
                height: 15px;
                box-sizing: content-box;
                padding: 15px;
                outline: none;
                border-radius: 5px;
                border: 1px solid #B5B0B0;
                color: #6E6E6E;
            }

            .required {
                border-color: red !important;
            }

            .inputTimeContainer {
                display: flex;
                // justify-content: space-between;
                column-gap: 1%;
                // justify-content: left;

                .DateStart,.DateFinished,.DateFinished,.timeStart,
                .timeEnd {
                    width: 50% !important;
                    margin: 0px;
                    position: relative;
                    color: #6E6E6E;
                    outline: none;

                    span,
                    label {
                        position: absolute;
                        float: left;
                        left: 15px;
                        top: -9px;
                        text-align: left;
                        background-color: #ffff;
                        font-size: 10pt;
                    }

                    input {
                        width: 100%;
                        height: 45px;
                        box-sizing: border-box;
                        padding: 15px;
                        outline: none;
                        border-radius: 5px;
                        border: 1px solid #B5B0B0;
                    }
                }

                .timeEnd {
                    justify-content: right;
                }
            }
            .finished{
                display: grid;
                grid-template-columns: auto auto;
                gap: 15px;
                .DateStart,.DateFinished,.DateFinished,.timeStart,
                .timeEnd {
                    width: 100% !important;
                    margin: 0px;
                    position: relative;
                    color: #6E6E6E;
                    outline: none;
                }

            }
        }

        .formBottom {
            position: relative;
            width: 100%;
            display: flex;
            box-sizing: border-box;
            padding: 10px;

            div:nth-child(1){
                button {
                    font-size: 16px;
                    float: left;
                    color: #FFFF;
                    border-radius: 5px;
                    height: 35px;
                    letter-spacing: 2px;
                    border: 0px;
                    cursor: pointer;
                    padding: 1%;
                }

                .deleteButton {
                    background-color: #CC0202;
                    padding: 0% 25% 0% 25%
                }
                .disabled {
                    background-color: #757575;
                    color: #fff;
                    border: 2px solid #757575;

                    &:hover {
                        background-color: #757575;
                        color: #fff;
                        border: 2px solid #757575;
                    }
                }
            }
            >div:nth-child(2) {
                flex-grow: 1;
            }

            .removeContainer {
                width: fit-content;

                .removeButton {
                    background-color: #CC0202;
                    width: 40px;
                    border: 0px;
                    cursor: pointer;
                    padding: 3px;
                    border-radius: 5px;

                    img {
                        width: 25px;
                    }
                }
            }

            .buttonsContainer {
                display: flex;
                justify-content: right;
                gap: 2%;
                position: relative;
                box-sizing: border-box;
                width: max-content;

                button {
                    font-size: 16px;
                    float: left;
                    color: #FFFF;
                    border-radius: 5px;
                    height: 35px;
                    letter-spacing: 2px;
                    border: 0px;
                    cursor: pointer;
                    padding: 1%;
                }

                .deleteButton {
                    background-color: #CC0202;
                    width: 20%;
                }

                .confirmButton {
                    background-color: #0e076e;
                    width: 20%;
                    min-width: 100px;
                }

                .cancelButton {
                    background-color: #CC0202;
                    width: 20%;
                }


                .disabled {
                    background-color: #757575;
                    color: #fff;
                    border: 2px solid #757575;

                    &:hover {
                        background-color: #757575;
                        color: #fff;
                        border: 2px solid #757575;
                    }
                }
            }
        }
    }
}

.close {
    padding-left: 10px;
    padding-right: 10px;
    width: auto;
    float: right;
    color: #6E6E6E;
    background-color: #FFFF;
    border: none;
    cursor: pointer;
    position: relative;
    margin: 0px;
    transition: all 1s;
}

/*---------------------------------------------------------------------*/
/*Styles for screens between than 801px and 1024px*/
/*---------------------------------------------------------------------*/
@media screen and (max-width: 1024px) {
    .addEventForm{
        transform: translate(0%,0%) !important;
        top: 0% !important;
        left: 0% !important;
        width:90%;
    }
    /*Modal*/
    .modalForm{
        .formHeader {
            display: flex;
            padding: 10px;
            justify-content: space-between;
        }

        width: 70%;
    }
}

/*---------------------------------------------------------------------*/
/*Styles for screens under 800px
/*---------------------------------------------------------------------*/
@media screen and (max-width: 800px) {
    .addEventForm{
        transform: translate(0%,0%) !important;
        top: 0% !important;
        left: 0% !important;
        width:90%;
    }
    /*Modal*/
    .modalForm{
        width: 80%;
        letter-spacing: 0pt;

        .formBottom {

            .buttonsContainer {
                .confirmButton {
                    width: 30%;
                    min-width: 100px;
                }

                .cancelButton {
                    width: 30%;
                    min-width: 100px;
                }
            }
        }

    }

}

/*---------------------------------------------------------------------*/
/*Styles for screens under 600px 
/*---------------------------------------------------------------------*/
@media screen and (max-width: 600px) {
    .addEventForm{
        transform: translate(0%,0%) !important;
        top: 0% !important;
        left: 0% !important;
        width:90%;
    }
    /*Modal*/
    .modalForm{
        width: 100%;

        .formBottom {

            .buttonsContainer {
                .confirmButton {
                    width: 35%;
                    min-width: 100px;
                }

                .cancelButton {
                    width: 35%;
                    min-width: 100px;
                }
            }
        }

    }

}

/*---------------------------------------------------------------------*/
/*Styles for screens under 400px 
/*---------------------------------------------------------------------*/
@media screen and (max-width: 450px) {
    .addEventForm{
        transform: translate(0%,0%) !important;
        top: 0% !important;
        left: 0% !important;
        width:90%;
        min-width: none;
    }
    /*Modal*/
    .modalForm{
        width: 100%;
        letter-spacing: 0pt;
        .formClass{
            .formBody{
                .inputTimeContainer{
                    display: grid;
                    gap: 15px;
                    grid-template-columns: repeat(1,100%);
                    .DateStart,.DateFinished,.timeStart,.timeEnd{
                        width: 100%!important;
                    }
                }
            }
        }
        .formBottom {
            div:nth-child(1){
            
                .deleteButton {
                    font-size: 12px !important;
                    min-width: 60px !important;
                    letter-spacing: 0px !important;

                }
            }
            .buttonsContainer {
                .confirmButton {
                    width: 35% !important;
                    font-size: 10pt !important;
                    min-width: 60px !important;
                    letter-spacing: 0px !important;
                }

                .cancelButton {
                    width: 35% !important;
                    font-size: 10pt !important;
                    min-width: 60px !important;
                    letter-spacing: 0px !important;
                }
            }
        }

    }

}