@import 'src/_variables.scss';

.loading-spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    height: 100%;
    width: 100%;
    top: 0;
}

.loading-spinner {
    position: relative;
    height: 200px;
    width: 200px;

    .lodingcircle1 {
        width: 15%;
        height: 15%;
        background-color: $sistemred;
        border-radius: 100%;
        position: absolute;
        top: 0px;
        left: 42.5%;
        animation-name: moving1;
        animation-duration: 0.7s;
        animation-iteration-count: infinite;
        animation-direction: alternate-reverse;
        animation-timing-function: linear;
    }

    .lodingcircle2 {
        width: 15%;
        height: 15%;
        background-color: $sistemred;
        border-radius: 100%;
        position: absolute;
        top: 10%;
        left: 5%;
        animation-name: moving2;
        animation-duration: 0.65s;
        animation-iteration-count: infinite;
        animation-direction: alternate-reverse;
        animation-timing-function: linear;
    }

    .lodingcircle3 {
        width: 15%;
        height: 15%;
        background-color: $sistemred;
        border-radius: 100%;
        position: absolute;
        top: 42.5%;
        left: 0%;
        animation-name: moving3;
        animation-duration: 0.6s;
        animation-iteration-count: infinite;
        animation-direction: alternate-reverse;
        animation-timing-function: linear;
    }

    .lodingcircle4 {
        width: 15%;
        height: 15%;
        background-color: $sistemred;
        border-radius: 100%;
        position: absolute;
        top: 80%;
        left: 5%;
        animation-name: moving4;
        animation-duration: 0.7s;
        animation-iteration-count: infinite;
        animation-direction: alternate-reverse;
        animation-timing-function: linear;
    }

    .lodingcircle5 {
        width: 15%;
        height: 15%;
        background-color: $sistemred;
        border-radius: 100%;
        position: absolute;
        top: 85%;
        left: 42.5%;
        animation-name: moving5;
        animation-duration: 0.65s;
        animation-iteration-count: infinite;
        animation-direction: alternate-reverse;
        animation-timing-function: linear;
    }

    .lodingcircle6 {
        width: 15%;
        height: 15%;
        background-color: $sistemred;
        border-radius: 100%;
        position: absolute;
        top: 80%;
        right: 5%;
        animation-name: moving6;
        animation-duration: 0.6s;
        animation-iteration-count: infinite;
        animation-direction: alternate-reverse;
        animation-timing-function: linear;

    }

    .lodingcircle7 {
        width: 15%;
        height: 15%;
        background-color: $sistemred;
        border-radius: 100%;
        position: absolute;
        top: 42.5%;
        right: 0;
        animation-name: moving7;
        animation-duration: 0.7s;
        animation-iteration-count: infinite;
        animation-direction: alternate-reverse;
        animation-timing-function: linear;
    }

    .lodingcircle8 {
        width: 15%;
        height: 15%;
        background-color: $sistemred;
        border-radius: 100%;
        position: absolute;
        top: 10%;
        right: 5%;
        animation-name: moving8;
        animation-duration: 0.65s;
        animation-iteration-count: infinite;
        animation-direction: alternate-reverse;
        animation-timing-function: linear;
    }

}

/* The animation code */
@keyframes moving1 {
    from {        
    }
    to {       
        transform: scale(50%,50%);
    }
}
@keyframes moving2 {
    from {        
    }
    to {
        transform: translate(100%, 100%);
    }
}
@keyframes moving3 {
    from {        
    }
    to {
        transform: scale(50%,50%);
    }
}
@keyframes moving4 {
    from {        
    }
    to {
        transform: translate(150%, -100%);
    }
}
@keyframes moving5 {
    from {        
    }
    to {
        transform: scale(50%,50%);
    }
}
@keyframes moving6 {
    from {        
    }
    to {
        transform: translate(-100%, -100%);
    }
}
@keyframes moving7 {
    from {        
    }
    to {
        transform: scale(50%,50%);
    }
}
@keyframes moving8 {
    from {        
    }
    to {
        transform: translate(-100%, 100%);
    }
}

.percentage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20pt;
    color: white;
}

