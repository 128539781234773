@import 'src/_variables.scss';


.academic-data {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(min(100%,400px),1fr));
    grid-auto-rows: 1fr;
    width: 100%;
    gap: 6%;

    .section {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-wrap: wrap;
        flex-grow: 1;

        .title {
            font-size: $caption;
            font-weight: 600;
            color: #5375f1;

        }

        .input-container {
            margin-top: 10px;
        }
        select{
            width: 100%;
        }
    }
    .data-groups{
        button{
            margin-top: 20px;
        }
    }
    .academic-notes{
        grid-column: 1;
        grid-row: 2;
    }
    .data-attendance{
        // width: 50%;
        // height: 100%;
        // grid-column: 2;
        grid-row: span 2;
    }
}