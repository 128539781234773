@import 'src/_variables.scss';

.password-alert {
    position: relative;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: min(400px, 100vw);

    .password-alert-header {
        width: 100%;
        h2 {
            font-size: $title;
            font-family: $fonttype;
            text-align: center;
            margin: .83em 0 .83em 0;
        }
    }

    .password-alert-body {
        width: 100%;

        p {
            font-size: $caption;
            font-family: $fontthin;
            text-align: center;
            margin-bottom: .5em;
        }
    }

    .password-alert-inputs {
        width: 100%;
        margin: 10px;
        .input-container{
            margin-bottom: 10px;
        }
    }

    .password-alert-buttons {
        width: 100%;
        margin-top: 10px;
        height: 50px;
        display: flex;
        justify-content: center;
        gap: 5%;
    }
}