.modal-component-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    .modal-content {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        border-radius: 8px;
        height: fit-content;
        max-height: 90vh;
        overflow: scroll;
        position: relative;
        padding:20px; 
        box-sizing:border-box;
        margin-top: 60px;
        .modal-close-button {
            position: absolute;
            top: 10px;
            right: 10px;
            background: none;
            border: none;
            font-size: 24px;
            cursor: pointer;
            .icon{
                width: 20px;
            }
        }
    }      
}
