@import 'src/_variables.scss';

.list-groups-modal-teacher-class {
    width: 100%;
    height: fit-content;
    padding: max(2%, 10px);
    border-radius: 10px;
    box-sizing: border-box;
    font-family: $fonttype;

    .list-groups-header {
        position: relative;
        display: flex;
        margin-bottom: 20px;
        flex-wrap: wrap;
        justify-content: flex-end;

        .close-button {
            background: transparent;
            border: none;
            font-size: $title;
            font-family: calibri;
            font-weight: 200;
            height: fit-content;
            width: fit-content;
            margin-bottom: 20px;
            cursor: pointer;
            svg {
                background-color: transparent;
            }
        }

        .header-title {
            width: 100%;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 10px 5px;
            h1 {
                font-size: 24pt;
                font-weight: 400;
                height: fit-content;
                margin: 0;
            }

            .order-close {
                display: flex;
                width: max(750px, 40%);
                justify-content: right;
                gap: 20px;

                .order-select {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    width: min(100%, 300px);

                    label {
                        font-size: 1.1em;
                    }
                }


            }
        }
    }

    .classroom-contain {
        .section-content {
            border-top: 1px solid $lightgray;

            h1 {
                font-size: $title2;
                margin: 0;
                line-height: 35px;
            }

            span {
                line-height: 25px;
            }


        }
    }
}