@import '../../variables';

/*-------------------------------------------------------------------------------------------------------*/
/*Modal*/
/*-------------------------------------------------------------------------------------------------------*/
.box {
    position: relative;
    min-height: 400px;
    height: 90%;
    width: 70%;
    border-radius: 7px;
    margin: 0px;
    padding: 3%;
    background-color: #FFFF;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 13.5pt;
    letter-spacing: 0.6pt;
    justify-content: center;
    box-sizing: border-box;

    /*-------------------------------------------------------------------------------------------------------*/
    /*Modal Header*/
    /*-------------------------------------------------------------------------------------------------------*/
    .modalHeader {
        width: 100%;
        display: flex;
        padding-bottom: 2%;
        align-items: center;
        height: 12%;
        display: flex;
        justify-content: space-between;
        /*Modal Title*/
        .reporttitle {
            width: 50%;
            font-size: 20pt;
            text-align: left;
            position: relative;

            .paragraphTitle {
                margin: 0px;
            }
        }

        /*Filter section*/
        .filter {
            width: 50%;
            display: flex;
            box-sizing: border-box;
            .filterlabel {
                font-size: 20px;
                height: 15px;
                width: 25%;
                background-color: #FFFF;
                color: #6E6E6E;
                justify-content: right;
                text-align: right;
                align-items: center;
                margin: 8px 3% 0px 10%;
                transition: all 0.3s;
            }

            .filterselect {
                width: 72%;
                height: 40px;
                position: relative;
                font-size: 20px;
                letter-spacing: 1px;
                color: #6E6E6E;
                padding: 5px;
                border-color: #B5B0B0;
                border-width: 1px;
                border-radius: 5px;
            }
        }
    }

    .modalFooter {
        height: 6%;
    }




    /*-------------------------------------------------------------------------------------------------------*/
    /*Table styles*/
    /*-------------------------------------------------------------------------------------------------------*/

    .modalBody {
        height: 78%;
        display: block;
        overflow-y: scroll;
        box-sizing: border-box;
        .p-datatable-responsive-scroll{
            height: 100%;
        }
        .p-datatable-wrapper {
            table {
                border-spacing: 0px;
    
                thead {
                    font-weight: 700;
                    background-color: $lightgray;
                    color: #FFFF;
                    transition: box-shadow 0.2s;
                    border: 0px;
    
                    th {
                        position: static;
                    }
    
                    div {
                        /*th div*/
                        width: 95%;
                        padding: 2% 0px 2% 0px;
                        align-items: center;
                        justify-content: center;
                        box-sizing: border-box;
    
                        .p-column-title {
                            text-wrap: wrap;
                            border-collapse: collapse;
                            border: 0px;
                        }
                    }
                }
    
                tbody {
                    tr:hover>td {
                        transition-duration: 0.2s;
                        filter: brightness(0.7);
                    }
    
                    tr {
                        height: 30px;
    
                        .td {
                            text-align: center;
                        }
    
                        td {
                            span>.span {
                                text-align: left;
                                font-size: 12pt;
                            }
                        }
                    }
    
                    .tableCtype {
                        display: none;
                    }
                    .tableCtype0 {
                        width: 2%;
                    }
                    .tableCtype1 {
                        width: 5%;
                    }
    
                    .tableCtype2 {
                        width: 10%;
                    }
    
                    .tableCtype3 {
                        width: 13%;
                    }
    
                    .tableCtype4 {
                        width: 15%;
                    }
    
                    .tableCtype5 {
                        width: 25%;
                    }
    
                    .tableCtype6 {
                        width: 30%;
                    }
                }
    
            }
    
            /*-------------------------------------------------------------------------------------------------------*/
            /*Scroll bar*/
            /*-------------------------------------------------------------------------------------------------------*/
            &::-webkit-scrollbar {
                width: var(--scroll-size, 10px);
            }

            &::-webkit-scrollbar-track {
                background-color: var(--scroll-track, transparent);
                border-radius: var(--scroll-track-radius, var(--scroll-radius));
            }

            &::-webkit-scrollbar-thumb {
                background-color: var(--scroll-thumb-color, grey);
                background-image: var(--scroll-thumb, none);
                border-radius: var(--scroll-thumb-radius, var(--scroll-radius));
                --scroll-radius: 10px;
                --scroll-size: 10px;
                --scroll-track: rgba(173, 173, 173, 0.1);
                --scroll-thumb: linear-gradient(45deg, #e7e7e7, #b4b1b1);
            }
        }

    }

    ////Button Add
    .buttonContainer {
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
        gap: 5px;
        button {
            border-radius: 100%;
            width: 60px;
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: none;

            i {
                font-size: 30px;
                color: #9ca3af;
            }

            &:hover {
                cursor: pointer;

            }
        }
    }

    /*-------------------------------------------------------------------------------------------------------*/
    /*Selected row*/
    /*-------------------------------------------------------------------------------------------------------*/
    .selected-row {
        filter: brightness(0.7);
        color: white !important;
        /* td{border-bottom: 3px solid white; border-top: 3px solid white; &:first-child{ border-left: 3px solid white;} &:last-child{ border-right: 3px solid white;}} */
    }
    
}

/*This are the buttons to open de modal if you want to change it to another file*/
// .functionButton {
//     box-sizing: border-box;
//     width: fit-content;
//     padding: 5px;
//     background-color: #1976d2;
//     border: 0px;
//     border-radius: 3px;
//     font-family: Calibri;
//     font-size: 14pt;
//     letter-spacing: 1px;
//     color: white;
//     position: relative;
// }

/*---------------------------------------------------------------------*/
/*Styles for screens between than 801px and 1024px*/
/*---------------------------------------------------------------------*/
@media screen and (max-width: 1024px) and (min-width: 801px) {

    /*Modal*/
    .box {
        width: 85%;

        .modalHeader {

            .filter {
                .filterlabel {
                    width: 25%;
                }

                /*filter section*/
                .filterselect {
                    width: 75%;
                }
            }
        }

        .modalBody {
            table {

                tbody {

                    /*Table columns*/
                    .tableCType1 {
                        min-width: 30px;
                    }

                    .tableCtype2 {
                        min-width: 50px;
                    }

                    .tableCtype3 {
                        min-width: 70px;
                    }

                    .tableCtype4 {
                        min-width: 80px;
                    }

                    .tableCtype5 {
                        min-width: 90px;
                    }

                    .tableCtype6 {
                        min-width: 100px;
                    }

                }
            }
        }
    }
}

/*---------------------------------------------------------------------*/
/*Styles for screens between than 801px and 1024px*/
/*---------------------------------------------------------------------*/
@media screen and (max-width: 800px) {

    /*Modal*/
    .box {
        width: 90%;
        letter-spacing: 0pt;

        .modalHeader {

            /*Modal header*/
            .reporttitile {
                width: 60%;
                font-size: 20px;
                padding: 2%;
                text-align: left;
                position: relative;
            }

            /*filter section*/
            .filter {
                width: 40%;
                align-items: center;

                .filterlabel {
                    width: 7%;
                    min-width: 44px;
                    height: auto;
                    font-size: 15px;
                    text-align: left;
                    padding-left: .6%;
                    transform: translate(7%, -110%);
                    transition: transform 0.5s;
                    margin: 0px 0px 0px .5%;
                    position: absolute;
                    z-index: 1;
                    background-color: white;
                }

                .filterselect {
                    width: 100%;
                    font-size: 15px;
                    text-align: left;
                }
            }

        }

        /*Table*/
        .modalBody {

            table {
                font-size: 8pt;

                .tableHeader {
                    display: flex !important;
                    position: relative;
                }

                tbody {
                    tr {
                        td {
                            span {
                                .span {
                                    font-size: 12px;
                                }
                            }
                        }
                    }

                    /*Table columns*/
                    .tableCType1 {
                        min-width: 20px;
                    }

                    .tableCtype2 {
                        min-width: 30px;
                    }

                    .tableCtype3 {
                        min-width: 50px;
                    }

                    .tableCtype4 {
                        min-width: 60px;
                    }

                    .tableCtype5 {
                        min-width: 65px;
                    }

                    .tableCtype6 {
                        min-width: 80px;
                    }
                }
            }


        }

        .modalFooter{
            ////Button Add
    .buttonContainer {
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;

        button {
            border-radius: 100%;
            width: 50px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: none;

            i {
                font-size: 30px;
                color: #9ca3af;
            }

            &:hover {
                cursor: pointer;

            }
        }
    }
        }
    }

}

/******************************************************
Books General
*******************************************************/
.box {
    .yellowbook {
        background-color: #FDD835;
        color: #FFFF;
    }

    .redbook {
        background-color: #F45C43;
        color: #FFFF;
    }

    .bluebook {
        background-color: #1A2980;
        color: #FFFF;
    }

    .greenbook {
        background-color: #1D976C;
        color: #FFFF;
    }

    .purplebook {
        background-color: #6E48AA;
        color: #FFFF;
    }

    .s1book {
        background-color: #F45C43;
        color: #FFFF;
    }

    .s2book {
        background-color: #1A2980;
        color: #FFFF;
    }

    .s3book {
        background-color: #1D976C;
        color: #FFFF;
    }

    .s4book {
        background-color: #FDD835;
        color: #FFFF;
    }

    .s5book {
        background-color: #6E48AA;
        color: #FFFF;
    }

    .s6book {
        background-color: #00F7FF;
        color: #FFFF;
    }

    .megabook {
        background-color: #FA9638;
        color: #FFFF;
    }
    
    .isOnline {
        background-color: $online;
        color: #FFFF;
    }

    .dayOff {
        background-color: $dissablegray;
        color: #FFFF;
    }

    .datestart {
        background-color: transparent;
        color: $activeblue;
        font-size: $paragraph !important;
        font-weight: bolder !important;
    }
    .soon{
        background-color: transparent;
        color: $activeblue;
        font-size: $paragraph !important;
        font-weight: bolder !important;
    }
}