@import "../../variables";

.contact-bubble {
  position: fixed;
  display: block;
  right: 1vw;
  height: 100px;
  width: 100px;
  z-index: 2;
  top: 80px;
  .contain {
    position: relative;

    .main-bubble {
      position: absolute;
      display: flex;
      height: 100%;
      width: 100%;
      background: radial-gradient(circle, rgba(216,19,14,1) 0%, rgba(200,35,31,1) 63%, rgba(161,40,37,1) 87%, rgba(122,41,39,1) 100%);
      justify-content: center;
      align-items: center;
      color: white;
      border-radius: 50%;
      cursor: pointer;
    }
    .toggle-checkbox {
      position: absolute;
      opacity: 0;
      height: 100%;
      width: 100%;
    }

    .bubble {
      height: 100px;
      width: 100px;
      border-radius: 50%;
    }

    .sub-bubble {
      position: absolute;
      display: flex;
      background-color: #1338BD;
      justify-content: center;
      align-items: center;
      color: white;
      transform: translateY(var(--move, 0));
      clip-path: var(--show, circle(0% at 50% 50%));
      transition: clip-path 0.3s ease;
      border: 2px solid #fff;
      a {
        margin: 0;
        padding: 0;
        .icon {
          height: 30px;
          width: 30px;
        }
      }
    }

    .toggle-checkbox:checked ~ .sub-bubble.call {
      --show: circle(30% at 50% 50%);
      --move: 100%;
    }

    .toggle-checkbox:checked ~ .sub-bubble.message {
      --show: circle(30% at 50% 50%);
      --move: 180%;
    }
  }
}

@media screen and (max-width:768px){
  .contact-bubble{
    height: 80px;
    width: 80px;
    .contain{
      .bubble {
        height: 80px;
        width: 80px;
        font-size:$paragraph;
      }
    }
  }
}

@media screen and (max-width:480px){
  .contact-bubble{
    height: 70px;
    width: 70px;
    .contain{
      .bubble {
        height: 70px;
        width: 70px;
        font-size:8pt;
      }
    }
  }
  
}