.recoverCodeContainer {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 14pt;  // Tamaño de fuente ajustado
    gap: 15px;
    padding: 0 20px;  // Añadido padding para evitar que el contenido toque los bordes en móviles
  
    .recoverAccountCodeForm {
      background-color: #E0E0E0;
      width: 100%;
      max-width: 450px;  // Ancho máximo para pantallas más grandes
      padding: 25px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
      text-align: justify;
  
      h1 {
        font-size: 18pt;  // Tamaño de título ajustado
        text-align: center;
      }
  
      h4 {
        font-size: 12pt;
        text-align: center;
      }
  
      .containerCodeInputAndMessage {
        p {
          font-size: 10pt;
          color: rgb(248, 86, 86);
          font-weight: bold;
          text-align: center;
        }
      }
    }
  }
  
  @media screen and (max-width: 768px) {
    .recoverCodeContainer {
      font-size: 13pt;
  
      .recoverAccountCodeForm {
        max-width: 400px;  // Ancho máximo ajustado para pantallas de tabletas
        padding: 20px;
  
        h1 {
          font-size: 16pt;
        }
  
        h4 {
          font-size: 11pt;
        }
      }
    }
  }
  
  @media screen and (max-width: 425px) {
    .recoverCodeContainer {
      font-size: 12pt;
      padding: 0 10px;  // Mayor padding lateral en móviles pequeños
  
      .recoverAccountCodeForm {
        width: 100%;
        max-width: 250px;  // Ajuste de ancho para pantallas móviles
        padding: 20px;
  
        h1 {
          font-size: 15pt;  // Ajuste de tamaño de título
        }
  
        h4 {
          font-size: 11pt;
        }
  
        .containerCodeInputAndMessage p {
          font-size: 10pt;
        }
      }
    }
  }
  
  @media screen and (max-width: 320px) {
    .recoverCodeContainer {
      font-size: 11pt;
      padding: 0 5px;  // Padding ajustado para pantallas muy pequeñas
  
      .recoverAccountCodeForm {
        width: 100%;
        padding: 15px;
  
        h1 {
          font-size: 14pt;  // Tamaño de título aún más pequeño
        }
  
        h4 {
          font-size: 10pt;
        }
  
        .containerCodeInputAndMessage p {
          font-size: 9pt;  // Reducido el tamaño del mensaje de error
        }
      }
    }
  }
  