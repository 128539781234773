@import 'src/_variables.scss';
footer {
    position: relative;
    display: block;
    background-color: $gray;
    width: 100%;
    bottom: 0;
    font-family: $fontthin;
    color: white;

    .links {
        width: 100%;
        padding: 0px 10px 20px 5%;
        display: flex;
        flex-wrap: wrap;
        letter-spacing: .8px;
        box-sizing: border-box;

        .about,
        .school {
            width: 20%;
            min-width: 300px;
            margin-right: 5%;
            display: grid;

            p {
                font-size: 16pt;
                padding: 20px 0px;
                font-weight: bold;
                &:hover{
                    cursor:default;
                }
            }

            .links_anchor {
                text-decoration: none;
                color: white;

                &:hover {
                    font-weight: 500;
                }
            }
        }

        .contact {
            min-width: 300px;
            display: block;

            .caption {
                font-size: 16pt;
            }


            p{
                font-weight: bold;
                padding: 20px 0px;
                &:hover{
                    cursor:default;
                }
            }
            .contact_element {
                display: flex;
                height: 25px;
                align-items: center;

                .contact_img {
                    width: 20px;
                    margin-right: 10px;
                }
            }
            .login_element {
                display: flex;
                height: 25px;
                align-items: center;

                a {
                    text-decoration: none !important;    
                    color: white !important;
                    &:hover{
                        cursor: pointer;
                    }
                }
                
            }
        }
    }

    .social_media {
        display: flex;
        background-color: rgb(255, 255, 255, .3);
        justify-content: center;
        padding: 5px 0px;
        gap: 25px;

        .social_img {
            position: relative;

            a {
                position: absolute;
                width: 100%;
                height: 100%;
            }

            img {
                height: 30px;

            }

            &:hover {
                transform: scale(105%);
                transition: all .2s;
            }
        }
    }
}