@import 'src/_variables.scss';


.print-format {
    width: min(600px, 100%);
    height: fit-content;
    padding: 15px;
    border-radius: 10px;
    box-sizing: border-box;
    margin-left: 2%;

    .print-header {
        display: flex;
        justify-content: space-between;

        h1 {
            font-size: 24pt;
            font-weight: 400;
            font-family: $fonttype;
            height: fit-content;
            margin: 0;
        }

        .close-button {
            background: transparent;
            border: none;
            font-size: $title;
            font-family: calibri;
            font-weight: 200;
            height: fit-content;

            svg {
                background-color: transparent;
            }
        }
    }
    .print-body{
        margin-top: 20px;

    }
    .print-footer{
        display: flex;
        margin-top: 20px;
        justify-content: flex-end;
        gap: 15px;
        button{
            height: 40px;
        }
    }
}