.containerEditModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border: 2px solid #000;
  width: 500px;
  padding: 25px;
  height: 80%;
  overflow-y: auto;

  .editContainer {
    display: flex;
    padding: 10px;
    font-family: "Montserrat", sans-serif;
    font-size: 15pt;
    gap: 15px;
    flex-direction: column;

    .formContainer {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 15px;

      .error {
        border: 1px solid red;
      }
    }

    .changePasswordContainerSA {
      padding: 0px;
      text-align: center;

      button {
        font-size: 15px;
        color: rgb(19, 56, 189);
        background-color: transparent;
        border: none;
        border-bottom: 1px solid rgb(19, 56, 189);

        &:hover {
          cursor: pointer;
        }
      }
    }

    .updateMessage {
      color: white;
      font-weight: bold;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      padding: 5px;
    }

    .bottomForm {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      font-size: 12px;
      gap: 12px;

      span {
        color: #000;
        font-weight: bolder;
      }

      a {
        color: rgb(248, 86, 86);
        font-weight: bold;
      }

      .button {
        background-color: #007BFF;
        border: none;
        color: white;
        padding: 10px 20px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 1rem;
        margin: 10px 0;
        cursor: pointer;
        border-radius: 5px;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: #0056b3;
        }
      }

      .disabledButton {
        background-color: #757575;

        &:hover {
          background-color: #757575;
        }
      }

      .disabled {
        background-color: #757575;
        color: #fff;
        border: 2px solid #757575;

        &:hover {
          background-color: #757575;
          color: #fff;
          border: 2px solid #757575;
        }
      }

    }

  }
}

.css-6z8jno {
  border-radius: 5px;
}

@media screen and (max-width:549px) {
  .containerEditModal {
    width: 300px;

    .editContainer {
      .formContainer {
        display: flex;
        flex-direction: column;



      }
    }
  }

}

@media screen and (max-width:360px) {
  .containerEditModal {
    width: 250px;
  }

}