@import 'src/_variables.scss';
* {
    margin: 0;
    padding: 0;
}
.homeContainer {
    font-family: $fontthin;
    min-height: calc(100vh - 372px);
    font-size: $caption;
    --opacidad-negro: .5;
    background-image: linear-gradient(rgba(0, 0, 0, var(--opacidad-negro)), rgba(0, 0, 0, var(--opacidad-negro))), url('../../assets/sn_04.jpg');

    .homeTitleContainer {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        font-family: $fontquicksand;

        .homeTitle {
            padding: 35px 50px 0px 0px;

            h1 {
                font-weight: normal;
                color: $titleColor;
                font-size: 80pt;
            }

            h3 {
                font-weight: normal;
                font-size: 65pt;
                color: #FFF;
            }
        }

    }

    .contentHome {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 10px;
        padding: 50px 0px 50px 0px;
       
    }
}
@media screen and (max-width: 768px) {
    .homeContainer{
        .homeTitleContainer{
            .homeTitle{
                margin-top: 25px;
                h1{
                    font-size:4rem;

                }
                h3{
                    font-size: 1.8em;
                }
            }
        }
        .contentHome{
            padding: 0px;
        }
    }
    
}