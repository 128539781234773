@import 'src/_variables.scss';

.cmContainer {
  min-height: calc(100vh - 372px);
  display: flex;
  flex-direction: column;
  font-family: $fontthin;
  font-size: $normalInfo;
  color: #fff;
  font-weight: bold;

  .cmPresentacion {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 500px;
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    background-position: 0px;
    position: relative;

    img {
      width: 600px;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(19, 56, 189, 0.2);
      z-index: 0;
    }
  }

  .contentCMContainer {
    z-index: 1;
    background: #fff;
    display: flex;
    flex-direction: column;

    .welcomeContainer,
    .experienceContainer,
    .methodContainer {
      display: flex;
      justify-content: space-between;
      padding: 25px;
      gap: 25px;

      .messageContainer1,
      .messageContainer2,
      .messageContainer3 {
        text-align: center;
        font-weight: normal;
        padding: 50px;
        // font-size: 25pt;

        .titleContainer {
          margin-bottom: 25px;
        }

        .bodyContainer {
          P {
            text-align: start;
          }
        }
      }

      .imageContainer {
        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .welcomeContainer .messageContainer1 {
      background-color: #1338BD;
      width: 60%;
    }

    .welcomeContainer .imageContainer {
      width: 40%;
    }

    .experienceContainer {
      flex-direction: row-reverse;

      .messageContainer2 {
        background-color: #F89896;
        width: 70%;
      }

      .imageContainer {
        width: 30%;
      }
    }

    .methodContainer .messageContainer3 {
      background-color: #91A7F4;
      width: 50%;
    }

    .methodContainer .imageContainer {
      width: 50%;
    }
  }

  // Media Queries
  @media (max-width: 1200px) {
    .contentCMContainer {

      .welcomeContainer,
      .methodContainer {
        flex-direction: column;
        align-items: center;

        .messageContainer1,
        .messageContainer3,
        .imageContainer {
          width: 100%;
          padding: 20px;
        }
      }

      .experienceContainer {
        flex-direction: column;
        align-items: center;

        .messageContainer2,
        .imageContainer {
          width: 100%;
          padding: 20px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    font-size: 15pt;

    .cmPresentacion {
      min-height: 300px;

      img {
        max-width: 400px;
      }
    }

    .contentCMContainer {
      padding: 15px;

      .welcomeContainer,
      .experienceContainer,
      .methodContainer {
        padding: 15px;

        .messageContainer1,
        .messageContainer2,
        .messageContainer3 {
          padding: 30px;
          // font-size: 20pt;
        }
      }
    }
  }

  @media (max-width: 480px) {
    font-size: 15pt;

    .cmPresentacion {

      img {
        max-width: 300px;
      }
    }

    .contentCMContainer {
      padding: 10px;

      .welcomeContainer,
      .experienceContainer,
      .methodContainer {
        padding: 10px;

        .messageContainer1,
        .messageContainer2,
        .messageContainer3 {
          padding: 20px;
          // font-size: 18pt;
        }
      }
    }
  }
}