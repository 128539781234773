.changePasswordContainer {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 14pt;  // Ajuste de tamaño de fuente
    gap: 15px;
    padding: 0 20px;  // Añadido padding para evitar que los elementos toquen los bordes en pantallas pequeñas
  
    .changePasswordForm {
      background-color: #E0E0E0;
      width: 100%;
      max-width: 450px;  // Ancho máximo para pantallas más grandes
      padding: 25px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
  
      h1 {
        font-size: 18pt;
        text-align: center;
      }
  
      .containerChangePasswordInputAndMessage {
        display: flex;
        flex-direction: column;
        gap: 15px;
  
        p {
          font-size: 10pt;
          color: rgb(248, 86, 86);
          font-weight: bold;
          text-align: center;
        }
  
        h4 {
          font-size: 12pt;
          color: green;
          text-align: center;
        }
      }
    }
  }
  
  @media screen and (max-width: 768px) {
    .changePasswordContainer {
      font-size: 13pt;
  
      .changePasswordForm {
        max-width: 400px;  // Ajuste del ancho para pantallas medianas
        padding: 20px;
  
        h1 {
          font-size: 16pt;
        }
  
        .containerChangePasswordInputAndMessage p {
          font-size: 9pt;
        }
      }
    }
  }
  
  @media screen and (max-width: 425px) {
    .changePasswordContainer {
      font-size: 12pt;
      padding: 0 15px;  // Ajuste del padding para pantallas más pequeñas
  
      .changePasswordForm {
        max-width: 250px;
        padding: 20px;
  
        h1 {
          font-size: 15pt;  // Ajuste del tamaño del título
        }
  
        .containerChangePasswordInputAndMessage p {
          font-size: 9pt;
        }
  
        .containerChangePasswordInputAndMessage h4 {
          font-size: 11pt;  // Tamaño del mensaje de éxito ajustado
        }
      }
    }
  }
  
  @media screen and (max-width: 320px) {
    .changePasswordContainer {
      font-size: 11pt;
      padding: 0 10px;
  
      .changePasswordForm {
        padding: 15px;
  
        h1 {
          font-size: 14pt;
        }
  
        .containerChangePasswordInputAndMessage p {
          font-size: 8pt;
        }
  
        .containerChangePasswordInputAndMessage h4 {
          font-size: 10pt;  // Tamaño del mensaje de éxito ajustado para pantallas pequeñas
        }
      }
    }
  }
  