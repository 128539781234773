.small{
    width: 20px;
    height: 20px;
    margin: 5px;
}
.medium{
    width: 30px;
    height: 30px;
    margin: 5px;

}
.large{
    width: 40px;
    height: 40px;
    margin: 5px;

}

/*Extras*/
.xl{
    width: 50px;
    height: 50px;
    margin: 5px;
}
.xxl{
    width: 60px;
    height: 60px;
    margin: 5px;
}
.xxxl{
    width: 70px;
    height: 70px;
    margin: 5px;
}