@import 'src/_variables.scss';

.snContainer {
  display: flex;
  flex-direction: column;
  font-family: $fontthin;
  font-size: $normalInfo;
  color: #fff;

  .parallaxSection {
    min-height: 500px;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    position: relative;

    .snTitleContainer {
      padding: 0 0 50px 50px;
      z-index: 1;
      height: 100%;

      h3 {
        font-weight: normal;
        color: $titleColor;
        font-size: 65pt;
      }

      h1 {
        font-size: 80pt;
        font-weight: normal;
      }
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 255, 0.18);
      z-index: 0;
    }

  }

  .acercaDeContainer {
    display: flex;
    z-index: 1;
    background-color: #fff;
    height: auto;
    justify-content: center;
    gap: 25px;
    padding: 25px;
    flex-wrap: wrap;

    .acercaDeBody {
      flex: 1 1 45%;
      display: flex;

      span {
        color: #000;
      }
    }

    .acercaDeImgContainer {
      flex: 1 1 45%;
      display: flex;
      justify-content: center;

      img {
        max-width: 100%;
        height: auto;
      }
    }
  }

  .ceContainer {
    color: #000;
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 25px;
    padding: 25px;

    .ceBody {
      flex: 1 1 45%;
      display: flex;
      flex-direction: column;
      gap: 25px;

      .ceTitle {
        color: $subTitle;

        h2 {
          font-weight: normal;
          // font-size: 25pt;
        }
      }

    }

    .ceImgContainer {
      flex: 1 1 45%;
      display: flex;
      justify-content: center;
      background-color: $lightgray;

      img {
        object-fit: cover;
        max-width: 100%;
        height: auto;
      }
    }
  }

  .misionVisionContainer {
    color: #fff;
    background: #5D7FB7;
    padding: 25px;
    gap: 25px;
    display: flex;
    flex-direction: column;
    font-weight: normal;

    .misionContainer,
    .visionContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 25px;

      .misionTitle,
      .visionTitle {
        text-align: center;

        h1,
        h3 {
          font-weight: normal;
        }
      }
    }
  }

  .valores {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 30px;
    padding: 100px 0;
    background-repeat: no-repeat;
    background-size: cover;

    .borderT {
      border-bottom: 5px solid #fff;
      width: 5%;
      height: 2px;
    }

    .vCaracteristicas {
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
      margin: 25px;
      justify-content: center;

      .vCalidadH,
      .vInteres,
      .vEnseñanza {
        flex: 1 1 30%;
        display: flex;
        gap: 15px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        background: rgba(19, 56, 189, 0.65);
        padding: 100px 50px;
        max-width: 300px;
      }
    }
  }
}

@supports (-webkit-touch-callout: none) {
  /* CSS specific to iOS devices */ 
  @media screen and (max-width: 1080px){
    .snContainer {
  
      .parallaxSection {
        background-attachment: local;
        
      }
    }
  }
}

@media screen and (max-width: 1024px){
  .snContainer {
    font-size: 1.3rem !important;

    .parallaxSection {
      .snTitleContainer {
        padding: 0 0 30px 30px;

        h3 {
          font-size: 1.8em;
        }

        h1 {
          font-size: 4rem;

        }
      }
    }

    .acercaDeContainer,
    .ceContainer {

      .acercaDeBody,
      .acercaDeImgContainer,
      .ceBody,
      .ceImgContainer {
        flex: 1 1 100%;
      }
    }

    .valores .vCaracteristicas .vCalidadH,
    .valores .vCaracteristicas .vInteres,
    .valores .vCaracteristicas .vEnseñanza {
      max-width: 250px;
    }
  }
}

@media screen and (max-width: 768px) {
  .snContainer {
    // font-size: 14pt !important;

    .parallaxSection {
      min-height: 250px;
      background-attachment: local;
      
      .snTitleContainer {
        padding: 0 0 20px 20px;


      }
    }

    .acercaDeContainer,
    .ceContainer {
      flex-direction: column;
      align-items: center;

      .acercaDeBody,
      .acercaDeImgContainer,
      .ceBody,
      .ceImgContainer {
        flex: 1 1 100%;
      }
    }

    .valores .vCaracteristicas .vCalidadH,
    .valores .vCaracteristicas .vInteres,
    .valores .vCaracteristicas .vEnseñanza {
      max-width: 200px;
    }
  }
}

@media screen and (max-width: 425px) {
  .snContainer {
    // font-size: 12pt !important;

    .parallaxSection {

      .snTitleContainer {
        padding: 0 0 10px 10px;

      }
    }

    .valores {
      background-position: -400px;
      background-size: 2500px;
      .vCaracteristicas .vCalidadH,
      .vCaracteristicas .vInteres,
      .vCaracteristicas .vEnseñanza {
        width: 300px;
        padding: 10px;
      }
    }
  }
}