@import 'src/_variables.scss';


.success-register {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); // Fondo semitransparente
    z-index: 1000; // Asegura que el modal esté por encima de otros elementos

    .success-register-content {
        padding: 2rem;
        border-radius: 8px;
        width: min(500px, 100%);
        max-width: 90%;
        text-align: center;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Sombra para darle efecto de elevación

        .success-header {
            position: relative;
            font-size: 1.2em;
            font-weight: bold;
            margin-bottom: 1.5rem;
            .close-button {
                position: absolute;
                top: -45px;
                right: -20px;
                background: transparent;
                border: none;
                font-size: $title;
                font-family: calibri;
                font-weight: 200;
                height: fit-content;
                cursor: pointer;
            }
        }

        .success-buttons {
            display: flex;
            justify-content: center;
            margin-top: 1.5em;
            gap: 10px;
        }
        .next-button{
            display: flex;
            justify-content: flex-end;
            margin-top: 30px;
        }

    }

}