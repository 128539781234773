@import 'src/_variables.scss';

.oferta {
    width: 100%;

    .oft_header {
        position: relative;
        display: flex;
        justify-content: center;
        color: white;
        font-family: $fontthin;
        font-weight: 300;
        padding: 0;
        font-size: 20pt;

        .oft_header_img {
            position: relative;
            width: 100%;
            height: 50vh;
            min-height: 500px;

            img {
                width: 100%;
                height: 50vh;
                min-height: 500px;
                object-fit: cover;
            }

            div {
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: rgba(255, 0, 0, 0.25);
                top: 0;
            }
        }

        .oft_header_text {
            position: absolute;
            font-size: 2em;
            top: 50%;
            margin: 0;
        }

        .oft_header_title1 {
            position: absolute;
            font-size: 3.25em;
            right: 5%;
            bottom: 13%;
            margin: 0;
            color: #D8130E;
        }

        .oft_header_title2 {
            position: absolute;
            font-size: 4em;
            right: 5%;
            bottom: 0%;
            margin: 0;

        }
    }

    .test {
        width: 120px;
        position: fixed;
        top: 30vw;
        right: 0;
        margin-right: 0;
        z-index: 1000;

        .test_container_label {
            position: relative;

            img {
                width: 100%;
                // width: 100px;
                opacity: 1;
            }

            .test_title {
                position: absolute;
                display: flex;
                align-items: center;
                top: 0;
                right: 5px;
                height: 100px;

                p {
                    color: white;
                    font-size: $normal;
                    text-align: right;
                    font-weight: 500;
                    &:hover{
                        cursor: pointer;
                    }
                }
            }

        }

        &:hover {
            transform: scale(105%);
            transition: all .2s;
        }
    }

    .paragraph {
        position: relative;
        text-align: center;
        width: 80%;
        margin-left: 10%;
        font-size: $title;

        .caption1 {
            font-size: 2em;
            font-weight: 100;
            margin: 0;
            color: #1338BD;
        }

        .caption2 {
            font-size: 1.5em;
            font-weight: 100;
            margin: 0;
            color: #1338BD;

        }

        p {
            margin-bottom: 50px;
            font-size: 1em;
        }
    }

    .parallax {
        min-height: 600px;
        background-attachment: fixed;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        position: relative;
    }

    .img-section {
        display: flex;
        position: relative;
        height: 600px;
        overflow: hidden;
        align-items: center;
        justify-content: center;

        img {
            position: absolute;
            width: 100vw;
            object-fit: cover;
        }
    }

}

@supports (-webkit-touch-callout: none) {
    @media screen and (max-width:1080px) {

        .oferta {


            .parallax {
                // background-size: 200%;
                background-attachment: local !important;
            }


        }
    }
}

@media screen and (max-width:1024px) {

    .oferta {
        .oft_header {
            .oft_header_text {
                font-size: 1em;
                text-align: center;
            }

            .oft_header_title1 {
                font-size: 1.8em;
                line-height: .5em;
            }

            .oft_header_title2 {
                font-size: 2.5em;
            }
        }
    }
}

@media screen and (max-width:768px) {

    .oferta {
        .test {
            width: 80px;

            .test_container_label {
                .test_title {
                    height: 70px;
                    p {
                        font-size: 9pt;
                    }
                }
            }

            &:hover {
                transform: scale(105%);
                transition: all .2s;
            }
        }

        .paragraph {
            .caption1 {
                font-size: 1.5em;
            }

            .caption2 {
                font-size: 1em;
            }

            p {
                margin-bottom: 50px;
                font-size: $normal;
            }
        }

        .parallax {
            min-height: 450px;
            // background-size: 200%;
            background-position: bottom;
        }

        .img-section {
            height: 300px;

            img {
                position: sticky;
                width: 150vw;
                object-fit: cover;
            }
        }
    }
}