.containerPasswordEditModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%; /* Cambiar de 400px a 90% del ancho de la pantalla */
  max-width: 500px; /* Establecer un ancho máximo */
  background-color: #fff;
  border: 2px solid #000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px; /* Ajustar el relleno para que sea más flexible */
  border-radius: 5px;

  .modalChangePasswordContainer {
    display: flex;
    flex-direction: column;
    font-family: "Montserrat", sans-serif;
    font-size: 1rem; /* Cambiar de 15pt a una unidad más flexible como rem */
    gap: 15px;

    .error {
      border-color: red;
    }

    .childButtons {
      display: flex;
      justify-content: space-between;
      gap: 10px; /* Añadir un espacio entre los botones */

      button {
        flex: 1; /* Asegurarse de que los botones ocupen espacio igualmente */
      }

      .disabled {
        background-color: #757575;
        color: #fff;
        border: 2px solid #757575;

        &:hover {
          background-color: #757575;
          color: #fff;
          border: 2px solid #757575;
        }
      }
    }
  }

  // Media Queries
  @media (max-width: 768px) {
    padding: 15px;

    .modalChangePasswordContainer {
      font-size: 0.875rem; /* Ajustar el tamaño de fuente en pantallas pequeñas */
      
      .childButtons {
        flex-direction: column; /* Poner los botones en una columna */
        gap: 10px; /* Espacio entre los botones */
      }
    }
  }

  @media (max-width: 480px) {
    padding: 10px;

    .modalChangePasswordContainer {
      font-size: 0.75rem; /* Ajustar aún más el tamaño de fuente en pantallas muy pequeñas */

      .childButtons {
        button {
          padding: 8px 16px; /* Ajustar el padding para pantallas pequeñas */
        }
      }
    }
  }
}