/*Fonts*/
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Coming+Soon&family=Gochi+Hand&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');

/*Text*/
$fonttype: 'Monserrat', sans-serif;
$fontcondensed: 'Roboto Condensed', sans-serif;
$fontquicksand:'Quicksand', sans-serif;
$fontkids:'Gochi Hand';
$fontthin: 'Quicksand';
$title: 20pt;
$title2: 16pt;
$caption: 14pt;
$normal: 12pt;
$paragraph: 10pt;
$normalInfo: 21pt;

/*Dimensions*/
$modalborders: 5px;

/*colors*/
$dissablegray: #757575;
$lightgray:    #B5B0B0;
$lightergray:    #E7E6E6;//Actualizado
$gray:         #595959;
$darkgray:     #404040;//Actualizado

$button: #5375f1; //Cambbio
$titleColor: #D8130E;
$save:   #182DCA;
$cancel: #FF010B;
$darkred: #530B0B;
$activeblue:#182DCA;
$activered:#D8130E;
$sistemred:#f85656;
$sistemblue:#1338bd;

$yellow: #FFC000;//Actualizado
$red:    #FF010B;//Actualizado
$blue:   #1A2980;//Actualizado
$green:  #00B050;//Actualizado
$purple: #6E48AA;
$smile1: #FF010B;
$smile2: #1A2980;
$smile3: #00B050;
$smile4: #FFC000;
$smile5: #6E48AA;
$smile6: #00F7FF;
$mega:   #D75E26;//Actualizado
$online:   #d72673;//Actualizado

$lightyellow: #FFD966;
$lightred:#FF4F4F;
$lightbue:#4472C4;
$lightgreen:#61FFA8;
$lightpurple:#D1B3CB;
$smile1light:#F27075;
$smile2light:#6176EE;
$smile3light:#7AEAAD;
$smile4light:#EFD68A;
$smile5light:#B38EED;
$smile6light:#B3F6F9;
$megalight:#F1B59A;


// Info Page
$titleColor: #D8130E;
$subTitle:#0E298D;

$smile6darker:rgb(102, 221, 228);
