@import 'src/_variables.scss';

.tm_container {
  font-family: $fontthin;
  margin-top: 60px;
  width: 100%;

  .tm_header {
    position: relative;

    .tm_header_img {
      display: grid;
      gap: .5rem;
      grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
      grid-template-rows: clamp(18rem, 30rem, 35rem) 7rem clamp(18rem, 30rem, 35rem);

      div {
        position: relative;
        display: flex;
        overflow: hidden;
      }

      .img1 {
        display: flex;
        grid-column: span 3;
        grid-row: 1;
        justify-content: flex-end;

        .img_bg {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }

        .img_front {
          position: absolute;
          height: 95%;
          object-fit: contain;
          bottom: 0;
          right: -20%
        }

        .img_front2 {
          display: none;
          position: absolute;
          height: 95%;
          object-fit: contain;
          bottom: 0;
          // right: 5%;
        }
      }

      .img2 {
        display: none;
        background-color: #C8D3F9;
        justify-content: center;
      }

      .img3 {
        display: none;
        grid-row: 1/span 3;
        grid-column: 6/ span 2;
        justify-content: center;

        .img_bg {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      .tm_title_container {
        width: 100%;
        display: flex;
        grid-column: 1/ span 3;
        grid-row: 2;
        background: $lightergray;
        align-items: center;
        justify-content: center;
        font-size: 12px;

        h3 {
          font-weight: normal;
          color: $titleColor;
          font-size: 3.5em;
          margin: 0 10px;
        }

        h1 {
          font-size: 3.5em;
          font-weight: normal;
          color: $titleColor;

        }
      }

      .img4 {
        display: flex;
        grid-column: 1;
        grid-row: 3;
        background-color: #FCCCCA;
        justify-content: center;
        img{
          object-fit: contain;
        }

      }

      .img5 {
        display: none;
        grid-column: 2;
        grid-row: 3;
        justify-content: center;

        .img_bg {
          height: 150%;
          width: 100%;
          object-fit: cover;
        }

        .img_front {
          position: absolute;
          height: 100%;
          object-fit: contain;
          bottom: 0;

        }
      }

      .txt {
        grid-column: span 2;
        grid-row: 3;
        background-color: $blue;
        padding: 20px;
        align-items: center;

        h2 {
          font-size: 1.2em;
          font-weight: 300;
          color: white;
          text-align: center;
        }
      }
    }


  }

  .paragraph {
    padding: 5% 10%;

    p {
      font-size: 1.3em;
      color: $activeblue;
      text-align: center;
    }
  }

  .tm_bottom {
    display: flex;
    justify-content: center;

    img {
      width: clamp(60%, 80%, 100%);
    }
  }
}

@media screen and (min-width:425px) {
  .tm_container {
    .tm_header {
      .tm_header_img {
        .img1 {
          .img_front {
            right: -10%;
          }

        }
      }
    }
  }
}
@media screen and (min-width:600px) {
  .tm_container {
    .tm_header {
      .tm_header_img {
        .tm_title_container {

          h3 {
            font-size: 4.5em;
          }

          h1 {
            font-size: 4.5em;
          }
        }

        .img1 {
          .img_front {
            right: 0;
          }

          .img_front2 {
            display: block;
          }
        }
      }
    }
  }
}

@media screen and (min-width:768px) {
  .tm_container {
    font-family: $fontthin;
    margin-top: 60px;
    width: 100%;

    .tm_header {

      .tm_header_img {
        .img2 {
          display: flex;
          img{
            object-fit: contain;
          }
        }

        .img4 {
          background-color: #FCCCCA;
          img{
            object-fit: contain;
          }
        }

        .tm_title_container {
          grid-column: 1/ span 4;


          h3 {
            font-size: 5.5em;
          }

          h1 {
            font-size: 5.5em;
          }

        }

        .img5 {
          display: flex;
        }

        .txt {
          h2 {
            font-size: 1.5em;
          }
        }
      }

    }

    .paragraph {
      p {
        font-size: $normalInfo;
      }
    }
  }
}

@media screen and (min-width:1024px) {
  .tm_container {
    font-family: $fontthin;
    margin-top: 60px;
    width: 100%;

    .tm_header {

      .tm_header_img {

        .img3 {
          display: flex;
        }

        .txt {
          h2 {
            font-size: 1.8em;
          }
        }
      }
    }


  }
}