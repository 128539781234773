@import 'src/_variables.scss';

/******************************************************
Books General
*******************************************************/

.level1book {
    background-color: $yellow;
    color: #FFFF !important;
}

.level2book {
    background-color: $red;
    color: #FFFF;
}

.level3book {
    background-color: $blue;
    color: #FFFF;
}

.level4book {
    background-color: $green;
    color: #FFFF;
}

.level5book {
    background-color: $purple;
    color: #FFFF;
}

.k1book {
    background-color: $smile1;
    color: #FFFF;
}

.k2book {
    background-color: $smile2;
    color: #FFFF;
}

.k3book {
    background-color: $smile3;
    color: #FFFF;
}

.k4book {
    background-color: $yellow;
    color: #FFFF;
}

.k5book {
    background-color: $smile5;
    color: #FFFF;
}

.k6book {
    background-color: $smile6;
    color: #FFFF;
}

.k7book {
    background-color: $mega;
    color: #FFFF;
}

.isOnline {
    background-color: $online;
    color: #FFFF;
}

.dayOff {
    background-color: $dissablegray;
    color: #FFFF;
}

.datestart {
    background-color: transparent;
    color: $activeblue;
    font-size: $paragraph !important;
    font-weight: bolder !important;
}