.recoverContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 14pt;  // Ajustamos el tamaño de fuente general
    gap: 20px;
    width: 100%;  // Ancho completo para mejor responsividad
    height: 100vh;
    .recoverAccountForm {
        background-color: #E0E0E0;
        width: 100%;
        max-width: 450px;  // Limitamos el ancho máximo del formulario
        padding: 25px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
        text-align: justify;

        h1 {
            font-size: 18pt;  // Tamaño de título ajustado
            text-align: center;
        }

        h4 {
            font-size: 12pt;
            text-align: center;
        }

        .containerEmailInputAndMessage {
            p {
                font-size: 10pt;
                color: rgb(248, 86, 86);
                font-weight: bold;
                text-align: center;  // Centramos el mensaje
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .recoverContainer {
        font-size: 13pt;

        .recoverAccountForm {
            padding: 20px;
            max-width: 400px;  // Ajustamos el ancho del formulario en tabletas
        }
    }
}

@media screen and (max-width: 425px) {
    .recoverContainer {
        font-size: 12pt;

        .recoverAccountForm {
            width: 100%;
            max-width: 250px;
            padding: 20px;

            h1 {
                font-size: 16pt;  // Tamaño más pequeño para pantallas móviles
            }

            h4 {
                font-size: 11pt;
            }

            .containerEmailInputAndMessage p {
                font-size: 10pt;
            }
        }
    }
}

@media screen and (max-width: 320px) {
    .recoverContainer {
        font-size: 11pt;

        .recoverAccountForm {
            padding: 15px;
            h1 {
                font-size: 14pt;  // Tamaño aún más pequeño para el título
            }

            h4 {
                font-size: 10pt;
            }

            .containerEmailInputAndMessage p {
                font-size: 9pt;  // Reducimos el tamaño del mensaje de error
            }
        }
    }
}
