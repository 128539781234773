@import 'src/_variables.scss';


.tabs-container {
  width: 100%;
  border-bottom: 1px solid #cccccc48;
  border-radius: 5px;
  // overflow: hidden;
  font-size: 14pt;

  .tabs-header {
    display: flex;
    border-bottom: 1px solid #cccccc48;
    gap: 5px;

    .tab {
      // flex: 1;
      padding: 10px 15px 10px 15px;
      text-align: center;
      cursor: pointer;
      border: none;
      outline: none;
      border-radius: 5px 5px 0 0;

      &:hover:not(.disabled) {
        background-color: #4a4a4a77;
      }

      &.active {
        font-weight: 600;
            border-bottom: 4px solid #5375f1;
      }

      &.disabled { 
        cursor: not-allowed;
        color: #999;
        background-color: $dissablegray;

      }
    }
  }

  .tabs-content {
    padding: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 8px 8px 0 rgba(0, 0, 0, 0.19);
    border-radius: 0px 10px 10px 10px;

    .tab-content {
      display: none;

      &:nth-child(1) {
        display: block;
      }
    }
  }
}