@import 'src/_variables.scss';

.instalacionesContainer {
    display: flex;
    flex-direction: column;
    font-family: $fontthin;
    color: #fff;
    font-size: $normalInfo;

    .iPrensentacionContainer {
        min-height: 500px;
        background-size: cover;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: 0px 0px 0px 25px;

        .iPresentacionTitle {
            z-index: 1;

            h3 {
                font-weight: normal;
                color: $titleColor;
                font-size: 65pt;
            }

            h1 {
                font-size: 80pt;
                font-weight: normal;
            }
        }

        .iPresentacionBody {
            z-index: 1;

            span {
                font-size: 28pt;
            }
        }

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(66, 135, 245, 0.18);
        }

        z-index: -1;
    }

    .iCardContainer {
        background-color: #fff;
        display: flex;
        justify-content: center;
        padding: 25px;

        .cardContainer1,
        .cardContainer2,
        .cardContainer3 {

            font-size: 20pt;


            .cardImgContainer {
                img {
                    width: 100%;
                }
            }

            .cardTitleContainer {
                h2 {
                    font-weight: normal;
                    color: #000;
                }
            }
        }

        .cardContainer1 {
            position: relative;
            left: 50px;
            z-index: 1;
            width: 30%; // Mantenemos el ancho original
        }

        .cardContainer2 {
            position: relative;
            text-align: center;
            width: 40%; // Mantenemos el ancho original
        }

        .cardContainer3 {
            position: relative;
            right: 50px;
            z-index: 1;
            text-align: end;
            width: 30%; // Mantenemos el ancho original
        }
    }

    .explora {
        height: 600px;
        background-color: #fff;
        color: #000;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 25px;
        padding: 25px;

        h1 {
            color: $subTitle;
            font-weight: normal;
            font-size: 45pt;
        }

        span {
            font-size: 25pt;
        }
    }


}
@media screen and (max-width: 1050px) {
    .instalacionesContainer {
        .iPrensentacionContainer {
            min-height: 300px;
            align-items: flex-start;
            padding: 0px 20px;
            
            .iPresentacionBody{
                margin-bottom: 20px;
            }
            .iPresentacionTitle {
                margin-top: 100px;
                h3 {
                    line-height: 10px;
                }
    
                h1 {
                    
                }
            }
        }

        .iCardContainer {
            flex-direction: column;

            .cardContainer1,
            .cardContainer2,
            .cardContainer3 {
                width: 100%; // Cambiamos a ancho completo
                margin-bottom: 20px; // Espacio entre tarjetas
                left: auto;
                right: auto;
            }

            .cardContainer2 {
                flex-direction: column-reverse;
                display: flex;
                flex: 0;
            }

            .cardContainer1 {
                .cardTitleContainer {
                    text-align: center; // Alineación de título
                }
            }

            .cardContainer3 {
                .cardTitleContainer {
                    text-align: center; // Alineación de título
                }
            }
        }



    }
}
@media screen and (max-width: 1024px) {
    .instalacionesContainer {
        .iPrensentacionContainer {
            justify-content: flex-end;

            .iPresentacionTitle {
                h3 {
                    font-size: 1.8em;
                }

                h1 {
                    font-size: 4rem;

                }
            }

            .iPresentacionBody {

                span {
                    font-size: 20pt;
                }
            }


        }

    }


}

@media screen and (max-width: 768px) {
    .instalacionesContainer {
        .iPrensentacionContainer {
            min-height: 300px;
            align-items: flex-start;
            padding: 0px 20px;
            .iPresentacionBody{
                margin-bottom: 20px;
            }
        }

        .iCardContainer {
            flex-direction: column;

            .cardContainer1,
            .cardContainer2,
            .cardContainer3 {
                width: 100%; // Cambiamos a ancho completo
                margin-bottom: 20px; // Espacio entre tarjetas
                left: auto;
                right: auto;
                font-size: 15pt;
            }

            .cardContainer2 {
                flex-direction: column-reverse;
                display: flex;
                flex: 0;
            }

            .cardContainer1 {
                .cardTitleContainer {
                    text-align: center; // Alineación de título
                }
            }

            .cardContainer3 {
                .cardTitleContainer {
                    text-align: center; // Alineación de título
                }
            }
        }



        .explora {
            padding: 0px 20px;

            h1 {
                font-size: 30pt;
            }

            span {
                font-size: 20pt;
            }


        }
    }
}


@media screen and (max-width: 425px) {
    .instalacionesContainer {

        .iPrensentacionContainer {
            height: 35vh;
            background-size: cover;
            gap: 25px;
            padding: 0px 20px;
            .iPresentacionTitle {
                h3 {
                    font-size: 1.8em;
                }

                h1 {
                    font-size: 3rem;

                }
            }

            .iPresentacionBody {

                span {
                    font-size: 12pt;
                    line-height: 1;
                }
            }
            
        }

        .explora {
            height: auto;
            padding: 0px 20px;


            h1 {
                font-size: 25pt;
            }

            span {
                font-size: 15pt;
            }

        }
    }
}