@import 'src/_variables.scss';

.direccionContainer {
  min-height: calc(100vh - 372px);
  margin-top: 60px;

  .disabled {
    background-color: gray !important;
  }

  .css-145v6pe-MuiButtonBase-root-MuiTabScrollButton-root.Mui-disabled {
    opacity: 100%;
    pointer-events: all;
    cursor: pointer;
  }

  .functionNav {
    width: 100%;
    display: flex;
    background-color: transparent;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;

    .functionButtonsDiv {
      display: flex;
    }

    .scheduleView {
      display: flex;
      text-align: center;
      color: $lightergray;
      align-items: center;
      justify-content: center;
      float: right;
      margin-right: 5px;

      .css-11qr2p8-MuiButtonBase-root-MuiButton-root {
        background-color: $gray;
      }

      .viewSpan {
        font-size: $caption;
        color: $gray;
        padding-right: 5px;
      }

      .divFunctionButton {
        padding: 0%;
        margin: 0%;
      }

      button {
        background-color: $lightergray;
        border-radius: $modalborders;
        display: flex;
        justify-content: center;

        .notChosen {
          color: $gray;

        }

        i {
          width: 50%;
        }
      }

    }
  }

  .filterClass {
    font-family: $fonttype;
    font-size: $caption;
    display: flex;
    align-items: center;
    margin-left: 6px;
    position: absolute;
    top: 100px;
    right: 5px;
    z-index: 1;
    color: $gray;

    input[type="checkbox"] {
      width: 20px;
      height: 20px;
      margin-left: 5px;
      // background-color: $gray;
      appearance: none;
    }

    input[type="checkbox"]:hover {
      filter: brightness(0.7);
    }

    .yellowcheck {
      border: 2px solid $yellow !important;

      &:checked {
        background-color: $yellow;
      }

      &:checked:after {
        background-color: $yellow;
      }
    }

    .redcheck {
      border: 2px solid $red !important;

      &:checked {
        background-color: $red;
      }

      &:checked:after {
        background-color: $red;
      }
    }

    .bluecheck {
      border: 2px solid $blue !important;

      &:checked {
        background-color: $blue;
      }

      &:checked:after {
        background-color: $blue;
      }
    }

    .greencheck {
      border: 2px solid $green !important;

      &:checked {
        background-color: $green;
      }

      &:checked:after {
        background-color: $green;
      }
    }

    .purplecheck {
      border: 2px solid $purple !important;

      &:checked {
        background-color: $purple;
      }

      &:checked:after {
        background-color: $purple;
      }
    }

    .smile6check {
      border: 2px solid $smile6 !important;

      &:checked {
        background-color: $smile6;
      }

      &:checked:after {
        background-color: $smile6;
      }
    }

    .megacheck {
      border: 2px solid $mega !important;

      &:checked {
        background-color: $mega;
      }

      &:checked:after {
        background-color: $mega;
      }
    }
  }


  .css-1419abu-MuiPaper-root {
    div:nth-child(2) {
      button {
        display: none !important;
      }
    }

  }

  .css-c6nzw4 {
    div:nth-child(2) {
      button {
        display: none !important;
      }
    }
  }
}

@media screen and (max-width:800px) {

  .direccionContainer {
    .filterClass {
      position: relative;
      width: 100%;
      top: 0;
      left: 0;
      background-color: white;
      margin: 0;
      margin-left: 5px;

      input[type="checkbox"] {
        width: 20px;
        height: 20px;
        margin-left: 5px;
        // background-color: $gray;
        appearance: none;
      }


    }
  }



}