@import 'src/_variables.scss';
.modal-alert {
    position: relative;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 400px;
    justify-content: center;
    .alert-header{
        h2 {
            font-size: $title;
            font-family: $fonttype;
            text-align: center;
            margin: .83em 0 .83em 0;
        }
    }
    .alert-body{
        width: 100%;
        p{                
            font-size: $caption;
            font-family: $fontthin;
            text-align: center;
            margin-bottom: .5em;
        }
    }
    .alert-buttons{
            margin-top: 10px;
            height: 50px;
            display: flex;
            gap: 5%;
    }
}


