.popContainerMain {
  width: 350px;

  .headerPop {
    padding: 10px;
    color: #fff;
    .headerButtons{
      display: flex;
      justify-content: flex-end;
      gap: 5px;
      button{
       background-color: transparent;
       border: none;
       &:hover{
        cursor: pointer;
       }
       i{
         color: #fff;

       } 
      }
    }

  }

  .bodyPop {
    padding: 10px;

    span {
      color: gray;
      display: flex;
      gap: 5px;
      font-size: 12px;

      i {
        font-size: 20px;
      }
    }
  }

}