.input-container-password {
  position: relative;
  width: 100%;
  padding: 0%;
  box-sizing: border-box;
  font-family: Calibri, sans-serif;

  >input {
    width: 100%;
    position: relative;
    // color: #757575;
    padding: 15px;
    border-radius: 5px;
    outline: none;
    border: 1px solid #B5B0B0;
    box-sizing: border-box;
    background-color: transparent;

    &:focus {
      border-color: #007bff;
      outline: none;
    }
  }

  .input-label {
    font-size: 14pt;
    width: fit-content;
    position: absolute;
    // color: #B5B0B0;
    letter-spacing: 1px;
    top: 20% !important; // hice este cambiio
    left: 2%;
    pointer-events: none;
    transition: all 0.4s ease-out;
  }

  /*input:focus + label makes the label do the transition when the input is on focus, and the input:not()*/
  /* in for the exception of the text in the input has been writed so this prevent the label retur to the original position */
  input:focus+label,
  input:not(:placeholder-shown)+label {
    opacity: 100%;
    transform: translateY(-110%);
    font-size: 10pt;
    height: 15px;
    top: 0% !important;
    left: 15px;
    color: #B5B0B0;
    ;
  }

  input:focus+span,
  input:not(:placeholder-shown)+span {
    opacity: 100%;
    transform: translateY(-50%);
    font-size: 10pt;
    height: 15px;
    top: -30%;
    left: 15px;
    color: #B5B0B0;
    ;
  }

  .visibility {
    position: absolute;
    top: 10px;
    right: 15px;
    transition:  .2s;
    &:hover {
      cursor: pointer;
      color: #007bff;
      
    }
  }
}