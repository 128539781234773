@import 'src/_variables.scss';
.dropdown-menu {
    width: 100%;
    margin: 0;
    padding: 0;

    .level1-li {
        list-style: none;
        font-size: $title2;
        margin: 0;

        .level1-anchor {
            position: relative;
            display: flex;
            text-decoration: none;
            width: 95%;
            height: 50px;
            align-items: center;
            color: inherit;
            padding: 0 0 0 5%;


            /*----Principal Icon Color------*/
            .red {
                path {
                    fill: $sistemred !important;
                }
            }

            .blue {
                path {
                    fill: $sistemblue !important;
                }
            }

            .disabled {
                color: $dissablegray;
                
                path {
                    fill: $dissablegray !important;
                }
            }

            /*------------------------------*/
            .level1-icon {
                position: relative;
                height: 24px;
                width: 24px;
            }

            .level1-span {
                margin-left: 5%;
                font-size: 1em;
                text-decoration: none;
                width: 75%;

            }

            .level1-check {
                position: absolute;
                width: 95%;
                height: 100%;
                top: 0;
                opacity: 0;
                cursor: pointer;
                z-index: 1;
                margin: 0;
                box-sizing: border-box;
            }

            .level1-arrow {
                position: relative;
                width: 30px;
                fill: $lightgray;
            }

            &:hover {
                cursor: pointer;
                background: rgba(188, 188, 188, 0.537);
            }
        }

        /*--------SUBLIST LEVEL2---------*/
        .level2 {
            display: grid;
            grid-template-rows: var(--rows, 0fr)0fr;
            transition: .3s grid-template-rows;
            padding-left: 10%;
            width: 90%;
            background-color: var(--bg, transparent);


            .level2-list {
                list-style: none;
                overflow: hidden;
                margin: 0;
                padding: 0;

                .level2-li {
                    display: block;
                    padding: 5% 0;

                    .level2-anchor {
                        display: flex;
                        position: relative;
                        text-decoration: none;
                        align-items: left;
                        transition: .2s all;
                        color: inherit;
                        height: 20px;

                        .level2-icon {
                            position: relative;
                            height: 24px;
                            width: 24px;
                        }

                        .level2-span {
                            margin-left: 5%;
                            font-size: .9em;
                            text-decoration: none;
                            width: 75%;
                        }

                        .level2-arrow {
                            position: relative;
                            height: fit-content;
                            width: 24px;
                            margin-right: 2%;
                        }

                        .level2-check {
                            position: absolute;
                            width: 95%;
                            height: 100%;
                            top: 0;
                            opacity: 0;
                            cursor: pointer;
                            z-index: 1;
                            margin: 0;
                        }

                        /*------sublist icon colors*------*/
                        .red {
                            path {
                                fill: $sistemred !important;
                            }
                        }

                        .blue {
                            path {
                                fill: $sistemblue !important;
                            }
                        }

                        .disabled {
                            color: $dissablegray;

                            path {
                                fill: $dissablegray !important;
                            }
                        }
                    }

                }

            }

            /*--------SHOWS SUBLIST---------*/
            &:has(:checked) {
                --rows2: 1fr;
                --bg: rgba(188, 188, 188, 0.537);
            }

            /*--------SUBLIST LEVEL 3---------*/

            .level3 {
                display: grid;
                grid-template-rows: var(--rows2, 0fr)0fr;
                transition: .3s grid-template-rows;
                padding-left: 10%;
                width: 100%;
                background-color: var(--bg, transparent);


                .level3-list {
                    list-style: none;
                    overflow: hidden;
                    margin: 0;

                    .level3-li {
                        display: block;
                        margin: 5% 0;

                        .level3-anchor {
                            display: flex;
                            position: relative;
                            text-decoration: none;
                            align-items: left;
                            transition: .2s all;
                            color: inherit;


                            .level3-icon {
                                position: relative;
                                height: fit-content;
                                width: 24px;
                                margin-right: 2%;
                            }

                            .level3-span {
                                font-size: .9em;
                                margin-left: 10px;
                            }

                            .level3-arrow {
                                position: relative;
                                height: fit-content;
                                width: 24px;
                                margin-right: 2%;
                            }

                            .level3-check {
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                top: 0;
                                opacity: 0;
                                cursor: pointer;
                                z-index: 1;
                                margin: 0;
                            }

                            /*------sublist icon colors*------*/
                            .red {
                                path {
                                    fill: $sistemred !important;
                                }
                            }

                            .blue {
                                path {
                                    fill: $sistemblue !important;
                                }
                            }

                            .disabled {
                                color: $dissablegray;

                                path {
                                    fill: $dissablegray !important;
                                }
                            }
                        }

                    }

                }

            }

        }

        /*--------SHOWS SUBLIST---------*/
        &:has(:checked) {
            --rows: 1fr;
            --bg: rgba(188, 188, 188, 0.537);
        }
    }
}