.containerSuperAdmin {
  min-height: calc(100vh - 192px);
  margin-top: 60px;

  .dataTableUser {
    .searcherContainer {
      padding: 15px;
      width: 250px;

      @media (max-width: 768px) {
        width: 200px;
      }

      @media (max-width: 480px) {
        width: 180px;
      }
    }
  }

  .dataTablePerson {
    .searcherContainerPerson {
      padding: 15px;
      width: 250px;

      @media (max-width: 768px) {
        width: 200px;
      }

      @media (max-width: 480px) {
        width: 180px;
      }
    }
  }
}
