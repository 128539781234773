@import 'src/_variables.scss';

.privContainer {
  display: flex;
  flex-direction: column;
  font-family: $fontthin;
  font-size: $normalInfo;

  .parallaxSection {
    min-height: 500px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    position: relative;

    .snTitleContainer {
      padding: 0 0 50px 50px;
      z-index: 1;
      height: 100%;

      h3 {
        font-weight: normal;
        color: $titleColor;
        font-size: 65pt;
        margin: 0;
        padding: 0;
      }

      h1 {
        font-size: 80pt;
        font-weight: normal;
        color: #fff;
        margin: 0;
        padding: 0;
      }
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(45, 51, 66, 0.95);
      z-index: 0;
      clip-path: polygon(0 0, 40% 0, 28% 100%, 0% 100%);

    }

  }

  .privTextContainer {
    z-index: 1;
    background-color: #fff;
    height: auto;
    justify-content: center;
    gap: 25px;
    padding: 25px 10vw;

    .txtContain {

      p {
        color: #000;
        text-align: justify;
      }

      h3 {
        color: $button;
      }

      ul {
        padding-left: 5%;
      }

      .listNumber {
        list-style-type: decimal;

        span {
          font-weight: bold;
        }
      }

      .listDots {
        list-style-type: circle;
      }
    }


  }


}

@media screen and (max-width: 1024px) {
  .privContainer {
    font-size: 1.3rem !important;

    .parallaxSection {
      .snTitleContainer {
        padding: 0 0 30px 30px;

        h3 {
          font-size: 1.8em;
        }

        h1 {
          font-size: 4rem;

        }
      }
    }

    .privTextContainer,
    .ceContainer {

      .txtContain {
        flex: 1 1 100%;
      }
    }


  }
}

@media screen and (max-width: 768px) {
  .privContainer {
    // font-size: 14pt !important;

    .parallaxSection {
      .snTitleContainer {
        padding: 0 0 20px 20px;


      }
    }

    .privTextContainer {

      .txtContain {

        p {
          a {
            overflow-wrap: anywhere;
          }
        }
      }
    }

    .privTextContainer,
    .ceContainer {
      flex-direction: column;
      align-items: center;

      .txtContain,
      .acercaDeImgContainer {
        flex: 1 1 100%;
      }
    }
  }

}

@media screen and (max-width: 425px) {
  .privContainer {
    // font-size: 12pt !important;

    .parallaxSection {
      .snTitleContainer {
        padding: 0 0 10px 10px;

      }
    }

    
  }
}