.LoginContainer {
  min-height: calc(100vh - 365px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px;
  font-family: "Montserrat", sans-serif;
  font-size: 15pt;
  gap: 15px;

  .login {
    display: flex;
    flex-direction: column;
    min-width: calc(50% - 350px);
    gap: 35px;

    .formContainer {
      display: flex;
      flex-direction: column;
      gap: 15px;
      width: 100%;

      span {
        font-size: 12px;
        color: #000;
        font-weight: bolder;
      }

      .bottomForm {
        font-size: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;

        span {
          color: #000;
          font-weight: bolder;
        }

        a {
          color: rgb(248, 86, 86);
          font-weight: bold;
        }

        .button {
          display: flex;
          justify-content: center;

          .buttonLogin {
            background-color: rgb(248, 86, 86);
            color: #fff;
            padding: 10px;
            font-size: 15px;
            transition: 0.2s ease-in-out;
            border: 2px solid rgb(248, 86, 86);
            font-weight: bold;

            border-radius: 5px;

            &:hover {
              background-color: transparent;
              border: 2px solid #000;
              color: rgb(248, 86, 86);
              cursor: pointer;
            }
          }
        }

        .hideForgottenPassword {
          display: none;
        }

        .forgottenPassword {
          display: block;
        }
      }

    }
  }
  .errorMessage {
    display: flex;
    justify-content: center;
    min-height: 20px; // Ajusta la altura según el tamaño del mensaje

    .spanErrorM {
      text-align: justify;
      font-weight: bolder;
      font-size: 12pt;
      color: rgb(248, 86, 86);
    }
  }
}

@media screen and (max-width:375px) {
  .LoginContainer {

    padding: 25px;
  }
}