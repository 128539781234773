@import '../../../variables';

.boxGroup {
    .modalHeader {
        height: 100px;
        .filter {
            display: block;
            width: 60%;
            .switch {
                width: 100%;
                display: grid;
                justify-content: right;
                float: right;
                align-items: baseline;

                .switchSelect {
                    height: 10px;
                    justify-content: right;
                    align-items: center;
                    display: flex;
                    margin: 5px 0px 15px 0px;
                }
            }

            .dateFilter {
                width: 45%;
                display: flex;
                margin-bottom: 5px;
                float: right;

            }
        }
    }
    .modalBody {
        overflow-y: hidden;

        //----------------------------------------------------------------------------------
        //TABLE HEADER
        //----------------------------------------------------------------------------------
        //Table header is the section where Matutino Vespertino and Fines de semana are
        .tableHeader {
            .header {
                display: flex;
                position: relative;
                background-color: white;

                //Div in blanc to give a space before Matutino
                .tblank {
                    width: 20%;
                    min-width: 65px;
                    box-sizing: border-box;
                }

                .morning {
                    width: 20%;
                    padding-left: 1%;
                    box-sizing: border-box;
                }

                .afternoon {
                    width: 40%;
                    padding-left: 1%;
                    box-sizing: border-box;
                }

                .weekend {
                    width: 20%;
                    padding-left: 1%;
                    box-sizing: border-box;
                }
            }
        }

        //--------------------------------------------------------
        //TABLE STYLES 
        //--------------------------------------------------------

        .groupDataTable {
            height: 100%;

            thead {
                top: -1px;

                tr:nth-child(1) {
                    background-color: white;
                    color: black;
                    font-size: $caption;

                    th {
                        div {
                            text-align: left;
                            justify-content: left;
                            padding-left: 15px;
                            box-sizing: border-box;
                        }
                    }
                }

                tr:nth-child(2) {
                    font-family: $fonttype !important;
                    font-size: $normal;
                    th:nth-child(1) {
                        background-color: white;
                        color: black;
                        font-size: $caption;
                    }

                    th:nth-child(2),
                    th:nth-child(3),
                    th:nth-child(4) {
                        border-top: 3px solid $activeblue;
                        background-color: white;
                        color: $activeblue;
                    }

                    th:nth-child(5),
                    th:nth-child(6),
                    th:nth-child(7),
                    th:nth-child(8),
                    th:nth-child(9),
                    th:nth-child(10) {
                        border-top: 3px solid $cancel;
                        background-color: white;
                        color: $cancel;

                    }

                    th:nth-child(11),
                    th:nth-child(12),
                    th:nth-child(13) {
                        border-top: 3px solid $gray;
                        background-color: white;
                        color: $gray;
                    }
                }
            }

            tbody {
                .teacher {
                    background-color: $gray;
                    color: white;
                    padding-left: 10px;
                    text-align: left;
                    font-size: $normal;
                }

                tr {
                    td {
                        background-color: $lightergray;
                        border-spacing: 0px;
                        text-align: center;
                        height: 50px;
                        min-height: 50px;
                        min-width: 50px;
                        position: relative;

                        &:nth-child(6) {
                            border-left: solid 2px $lightgray;
                        }

                        &:nth-child(12) {
                            border-left: solid 2px $lightgray;
                        }

                        &:nth-child(2),
                        &:nth-child(3),
                        &:nth-child(4),
                        &:nth-child(5),
                        &:nth-child(6),
                        &:nth-child(7),
                        &:nth-child(8),
                        &:nth-child(9),
                        &:nth-child(10),
                        &:nth-child(11),
                        &:nth-child(12),
                        &:nth-child(13) {
                            border-right: 1px solid rgb(241, 239, 239);
                            border-bottom: 1px solid rgb(241, 239, 239);
                        }


                        .tdDiv {
                            height: 60%;
                            width: 100%;
                            position: absolute;
                            top: 0px;

                            .groupDiv {
                                display: flex;
                                height: 100%;
                                font-size: $normal;
                                padding: 1%;
                                box-sizing: border-box;
                                justify-content: center;
                                align-items: center;
                                flex-wrap: wrap;
                                line-height: 2px;
                            }

                            .studentsqty {
                                height: 20px;
                                font-size: $paragraph;
                            }
                        }


                    }
                }

                /*-------------------------------------------------------------------------------------------------------*/
                /*Selected cell*/
                /*-------------------------------------------------------------------------------------------------------*/
                .p-highlight {
                    filter: brightness(0.7);
                }
            }

        }

    }
}

/*---------------------------------------------------------------------*/
/*Styles for screens between than 801px and 1200px*/
/*---------------------------------------------------------------------*/
@media screen and (max-width: 1200px) {
    .boxGroup {
        .modalHeader {
            .filter {
                .dateFilter {
                    width: 70%;
                }
            }
        }
        .modalBody {
            .groupDataTable {
                .tableHeader {
                    .header {

                        .tblank,
                        .morning,
                        .afternoon,
                        .weekend {
                            p {
                                align-items: center;
                                padding: 0%;
                                width: auto;

                            }
                        }
                    }

                }

                thead {
                    font-size: $paragraph;
                    width: 94.35%;
                    height: 35px;

                    tr {
                        letter-spacing: normal;

                        th:nth-child(1) {
                            left: 2.3%;
                            width: 16%;
                        }
                    }
                }

                tbody {
                    .teacher {
                        padding-left: 5px;
                        font-size: $paragraph;
                        width: 17%;
                    }

                    td {
                        min-width: none;

                        .tdDiv {
                            height: 100%;

                            .groupDiv {
                                height: 75%;
                                font-size: $paragraph;
                                box-sizing: border-box;
                                letter-spacing: normal;
                            }

                            .studentsqty {
                                height: 25%;
                                font-size: 8pt;
                            }
                        }


                    }

                }
            }
        }

    }
}

/*---------------------------------------------------------------------*/
/*Styles for screens under 800px
/*---------------------------------------------------------------------*/
@media screen and (max-width: 800px) {
    .boxGroup {
        width: 100% !important;
        .modalHeader {
            .filter {
                .dateFilter {
                    width: 70%;
                }
            }
        }
        .modalBody {
            .groupDataTable {
                thead {
                    tr {
                        th {
                            font-size: $normal !important;
                        }
                    }
                }
    
                tbody {
                    .teacher {
                        font-size: 9pt !important;
                        padding-left: 5px !important;
                    }
    
                    td {
                        background-color: $lightergray;
                        min-height: 50px;
                        min-width: 30px !important;
                        font-size: $paragraph !important;
    
                        .tdDiv {
                            .groupDiv {
                                height: 75%;
                                padding: 0.5%;
                                box-sizing: border-box;
                                font-stretch: condensed;
                            }
    
                            .studentsqty {
                                height: 20%;
                            }
                        }
    
    
                    }
    
                }
            }
        }
    }

}

/*---------------------------------------------------------------------*/
/*Styles for screens under 600px 
/*---------------------------------------------------------------------*/
@media screen and (max-width: 600px) {
    .boxGroup {
        width: 100% !important;
        height: 90%;
        .modalHeader {
            .filter {
                .switch {
                    font-size: $normal;
                }

                .dateFilter {
                    width: 80%;
                }
            }
        }
    }

    .modalBody {
        tbody {
            .teacher {
                font-family: $fontcondensed;
                font-size: 9pt;
            }

            td {
                // background-color: $lightergray;
                height: 50px;

                .tdDiv {
                    height: 30px;

                    .groupDiv {
                        font-size: 9pt;
                        align-items: center;
                        line-height: 14px !important;

                        :first-child {
                            color: transparent;

                            &::first-letter {
                                color: white !important;
                            }
                        }

                        span {
                            width: 14px;
                            height: 15px;
                            overflow: hidden;
                            white-space: nowrap;

                            &:last-child {
                                width: auto;
                            }
                        }
                    }
                }


            }

        }
    }
}



/*---------------------------------------------------------------------*/
/*Styles for screens under 400px 
/*---------------------------------------------------------------------*/
@media screen and (max-width: 480px) {
    .boxGroup {
        min-width: 320px;
        .modalHeader {
            .filter {
                .switch {
                    font-size: $paragraph;
                }

                .dateFilter {
                    width: 100%;
                }
            }
        }
    }

    .modalBody {
        .groupDataTable {
            min-width: 450px;

            thead {
                tr {
                    th {
                        letter-spacing: 0px;
                        font-size: 9.5pt !important;
                    }
                }
            }
        }
    }
}