@import 'src/_variables.scss';

.iContainer {
    margin-top: 60px;
    min-height: calc(100vh - 270px);

    .oft_header {
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        color: white;
        font-weight: 300;
        height: 500px;

        .oft_header_img {
            position: relative;
            width: 100%;

            .img1 {
                z-index: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .oft_header_text {
            position: absolute;
            top: 15%;
            left: 5%;
            margin: 0;
            font-weight: normal;
            color: #1338BD;
        }

        .oft_header_title1 {
            position: absolute;
            font-size: 65pt;
            right: 5%;
            bottom: 18%;
            margin: 0;
            color: #D8130E;
            font-weight: normal;

        }

        .oft_header_title2 {
            position: absolute;
            font-size: 80pt;
            right: 5%;
            bottom: 0%;
            margin: 0;
            font-weight: normal;

        }
    }

    
    .nextTermContainer{
        .buttonContainer{
            .buttonChange {
                display: flex;
                padding: 15px;
                background-color: $activered;
                width: 200px;
                border: 0;
                border-radius: 5px;
                justify-content: center;
                color: white;
                margin: 25px 0px 0px 25px;
                &:hover {
                    opacity: .8;
                    transform: scale(105%);
                    transition: all .2s;
                    cursor: pointer;
                }
            }
        }
        .postContainer {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
        }
    }
}
@supports (-webkit-touch-callout: none) {
    @media screen and (max-width:1080px) {
        .iContainer{
            .nextTermContainer{
                .buttonContainer{
                    .buttonChange{
                        font-size: $caption !important;
                    }
                }
            }
        }
    }
  }
@media screen and (max-width: 768px) {
    .iContainer{
        .oft_header{   
            height: 250px;
                h1{
                    font-size:4rem !important;

                }
                h3{
                    font-size: 1.8em !important;
                }
            
        }
    }
}
@media screen and (max-width: 375px) {
    .iContainer{
        .nextTermContainer{
            .buttonContainer{
                display: flex;
                justify-content: center;
                .buttonChange{
                    margin: 25px 0px 0px 0px ;
                }
            }
        }
    }
}