.registerFormContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;

    .registerForm {
        width: 100%;
        max-width: 700px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .titleForm h1 {
            font-size: 2rem;
            text-align: center;
        }

        .formInput {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: 20px;

            .leftInput,
            .rightInput {
                width: 48%; // Ajusta el ancho para que ambos contenedores ocupen igual espacio
                display: flex;
                flex-direction: column;
                gap: 15px; // Espacio entre los campos de entrada
            }
        }

        .inputAnimated {
            width: 100%;
            margin-bottom: 15px;
            padding: 10px;
            font-size: 1rem;
        }
        .error{
            border: 1px solid red;
        }
        .input-container-select {
            position: relative;
            width: 100%;
            padding: 0;
            box-sizing: border-box;
            font-family: Calibri, sans-serif;

            >input,
            >select {
                width: 100%;
                padding: 15px;
                border-radius: 5px;
                outline: none;
                border: 1px solid #B5B0B0;
                color: #6E6E6E;
                background-color: #FFFFFF;
                position: relative;
                box-sizing: border-box;
                appearance: none; // Oculta la flecha por defecto del select en la mayoría de los navegadores
            }

            .input-label {
                font-size: 14pt;
                position: absolute;
                background-color: #FFFFFF;
                color: #6E6E6E;
                letter-spacing: 1px;
                top: 50%;
                left: 15px;
                transform: translateY(-50%);
                pointer-events: none;
                transition: all 0.3s ease;
                padding: 0 5px;
                z-index: 1; // Permite que el select esté sobre la etiqueta por defecto
            }

            // Cuando el input o select tiene foco o contenido, ajusta la etiqueta
            input:focus+.input-label,
            input:not(:placeholder-shown)+.input-label,
            select:focus+.input-label,
            select:not([value=""])+.input-label {
                transform: translateY(-150%);
                font-size: 10pt;
                top: 30%;
                left: 10px;
                background-color: #FFFFFF;
                color: #6E6E6E;
                padding: 0 5px;
            }
        }

         


        .buttonForm {
            display: flex;
            justify-content: center;

            .button {
                background-color: #007BFF;
                border: none;
                color: white;
                padding: 10px 20px;
                text-align: center;
                text-decoration: none;
                display: inline-block;
                font-size: 1rem;
                margin: 10px 0;
                cursor: pointer;
                border-radius: 5px;
                transition: background-color 0.3s ease;

                &:hover {
                    background-color: #0056b3;
                }
            }

            .disabledButton {
                background-color: #757575;
                &:hover {
                    background-color: #757575;
                }
            }
        }

        .bottomForm {
            display: flex;
            justify-content: center;

            span {
                text-decoration: none;
            }
        }
        .message{
            font-weight: bold;
            text-align: center;
        }
    }

    /* Ajustes para pantallas pequeñas */
    @media screen and (max-width: 600px) {
        .registerForm {
            max-width: 100%;
            padding: 0 10px;

            .formInput {
                flex-direction: column; // Cambiar a columna para dispositivos móviles

                .leftInput,
                .rightInput {
                    width: 100%; // Ocupa todo el ancho en pantallas pequeñas
                }
            }

            .titleForm h1 {
                font-size: 1.5rem;
            }

            .inputAnimated {
                font-size: 0.9rem;
                padding: 8px;
            }

            .button {
                font-size: 0.9rem;
                padding: 8px 15px;
            }
        }

    }
}