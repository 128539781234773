@import '../../../variables';

.containerT {
  background-image: url("../../../assets/TeacherIcons/bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: row;
  min-height: calc(100vh - 60px);
  height: fit-content;
  margin-top: 60px;
  .mediaContainer {
    width: 100%;
    position: relative;


    .logo {
      position: absolute;
      width: 20%;
      right: 2vw;
      bottom: 2vh;
      z-index: 0;
      opacity: 0.3;
    }

    .mediaItems {
      position: relative;
      display: grid;
      grid-template-columns: 50% 50%;
      grid-template-rows: auto 100px;
      top: 0;
      padding: 15px;
      gap: 5px;
      z-index: 1;

      .audioPlayerContainer {
        grid-column: 1;
        grid-row: 1;
        position: absolute;
        top: 0;
        background-color: #f1f3f4;
        border-radius: 25px;
        width: 320px;

        .headerAudioPlayer {
          position: absolute;
          transform: translateY(50%);
          // top: ;
          right: 12px;
          z-index: 1;

          button {
            background-color: transparent;
            border: none;
            color: $lightgray;

            &:hover {
              cursor: pointer;
              color: $gray;
            }
          }
        }
      }

      .videoPlayerContainer {
        position: relative;
        // top: 60px;
        grid-column-start: 2;
        grid-row-end: 4;

        // margin-bottom: 50px;
        .headerVideoPlayer {
          display: flex;
          justify-content: flex-end;
          padding: 5px;
          background-color: #f1f3f4;
          opacity: .8;
          border-radius: 5px;

          button {
            background-color: transparent;
            border: none;
            color: $lightgray;

            &:hover {
              cursor: pointer;
              color: $gray;
            }
          }
        }
      }

      .pdfContainer {
        grid-column: 1;
        grid-column-end: 2;
        grid-row: 1;
        grid-row-end: 4;
        margin-top: 75px;

        .headerPdf {
          display: flex;
          justify-content: flex-end;
          background-color: #f1f3f4;
          opacity: .8;
          border-radius: 5px;
          padding: 5px;

          button {
            background-color: transparent;
            border: none;
            color: $lightgray;

            &:hover {
              cursor: pointer;
              color: $gray;
            }
          }
        }

        .pdfMain{
          position: relative; 
          width: 100%; 
          height: 90vh;
        }

        overflow: hidden;

        iframe {
          overflow: hidden;

          /*-------------------------------------------------------------------------------------------------------*/
          /*Scroll bar*/
          /*-------------------------------------------------------------------------------------------------------*/
          ::-webkit-scrollbar {
            width: var(--scroll-size, 10px);
            height: var(--scroll-size, 10px);
          }

          ::-webkit-scrollbar-track {
            background-color: var(--scroll-track, transparent);
            border-radius: var(--scroll-track-radius, var(--scroll-radius));
          }

          ::-webkit-scrollbar-thumb {
            background-color: var(--scroll-thumb-color, grey);
            background-image: var(--scroll-thumb, none);
            border-radius: var(--scroll-thumb-radius, var(--scroll-radius));
            --scroll-size: 10px;
            --scroll-track: rgb(255 255 255 / 10%);
            --scroll-thumb: linear-gradient(45deg, #FFFF, #8c8b8b, #5c5c5c, #8c8b8b, #FFFF);
          }
        }


      }

      .presentationContainer {
        position: relative;
        top: 75px;
        grid-column-start: 2;
        grid-row-end: auto;

        .headerPPTPresentation {
          display: flex;
          justify-content: space-between;
          padding: 5px;
          background-color: #f1f3f4;
          opacity: .8;
          border-radius: 5px;

          .headerImg {
            img {
              width: 2vw;
            }
          }

          button {
            background-color: transparent;
            border: none;
            color: $lightgray;

            &:hover {
              cursor: pointer;
              color: $gray;
            }
          }
        }


      }
    }

    // background: linear-gradient(217deg, rgba(241, 245, 247, 0.8), rgba(255, 255, 255, 0.342) 70.71%),
    //     linear-gradient(127deg, rgba(211, 153, 153, 0.8), rgba(255, 255, 255, 0.397) 70.71%),
    //     linear-gradient(336deg, rgba(101, 117, 168, 0.8), rgba(255, 255, 255, 0.445) 70.71%);
  }

}

/*-------------------------------------------------------------------------------------------------------*/
/*Scroll bar*/
/*-------------------------------------------------------------------------------------------------------*/
::-webkit-scrollbar {
  width: var(--scroll-size, 10px);
  height: var(--scroll-size, 10px);
}

::-webkit-scrollbar-track {
  background-color: var(--scroll-track, transparent);
  border-radius: var(--scroll-track-radius, var(--scroll-radius));
}

::-webkit-scrollbar-thumb {
  background-color: var(--scroll-thumb-color, grey);
  background-image: var(--scroll-thumb, none);
  border-radius: var(--scroll-thumb-radius, var(--scroll-radius));
  --scroll-size: 10px;
  --scroll-track: rgb(255 255 255 / 10%);
  --scroll-thumb: linear-gradient(45deg, #FFFF, #8c8b8b, #5c5c5c, #8c8b8b, #FFFF);
}

@media screen and (max-width: 1024px) {
  .containerT {

    .mediaContainer {

      .mediaItems {
        display: flex;
        flex-direction: column;
      }
      .presentationContainer {
        position: static !important;
      }

      .audioPlayerContainer {
        position: relative !important;
      }
    }

    // background: linear-gradient(217deg, rgba(241, 245, 247, 0.8), rgba(255, 255, 255, 0.342) 70.71%),
    //     linear-gradient(127deg, rgba(211, 153, 153, 0.8), rgba(255, 255, 255, 0.397) 70.71%),
    //     linear-gradient(336deg, rgba(101, 117, 168, 0.8), rgba(255, 255, 255, 0.445) 70.71%);
  }



}

@media screen and (max-width: 768px) {
  .containerT {

    .mediaContainer {

      .headerImg {
        img {
          width: 6vw !important;
        }
      }
      .audioPlayerContainer {
        position: relative !important;

        audio {
          width: 80%;
        }
      }
    }
  }

}

@media screen and (max-width: 320px) {
  .containerT {
    .mediaContainer {


      

      .audioPlayerContainer {
        width: 100% !important;
      }
    }
  }

}