@import '../../variables';
.pdfMedia {
    font-family: "Montserrat", sans-serif;
    color: $darkgray;
    .react-pdf__Document {
        .react-pdf__Page__canvas {
            margin: 0 auto;
            width: 100% !important;
            height: 100% !important;
        }

        .react-pdf__Page__textContent {
            width: 0px !important;
        }

    }
    .react-pdf__Page__annotations{
        display: none !important;
    }

    span {
        font-size: 0px !important;

    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance: textfield;
    }

    p{
        margin-bottom: 5px;
    }
    .labelAndButtonContainer{
        display: flex;
        justify-content: space-between;
        .pageNumberInput {
            font-family: "Montserrat", sans-serif;
            width: 40px;
            text-align: center;
            color: $darkgray;
            background-color: transparent;
            border: none;
            border-bottom: 2px solid $darkgray;
            &:focus {
                outline: none;
               
              }
              &::placeholder{
                color: $gray;
              }
        }
    
        .buttonContainer {
            display: flex;
            gap: 5px;
    
            button {
                background-color: transparent;
                border: none;
                color: $darkgray;
                font-size: 12px;
                text-align: center;
                display: flex;
                align-items: center;
                text-decoration: none;
                font-weight: bold;
                transition: 0.2s ease-in-out;
    
                &:hover {
                    color: $sistemred;
                    cursor: pointer;
                }
    
    
            }
    
        }
    }
}

@media screen and (max-width: 375px) {
    .pdfMedia {
        .buttonContainer {
            button {

                font-size: 12px;


            }

        }
    }
}