@import 'src/_variables.scss';
.cardContent {
    background: #f8f8f8;
    border-radius: 5px;
    margin: 10px;
    flex: 1 1 300px;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    -webkit-box-shadow: 10px 11px 13px -7px rgba(0, 0, 0, 0.77);
    -moz-box-shadow: 10px 11px 13px -7px rgba(0, 0, 0, 0.77);
    box-shadow: 10px 11px 13px -7px rgba(0, 0, 0, 0.77);

    .imageContainer {
        width: 100%;
        height: 200px;
        /* Ajusta esta altura según sea necesario */
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;

        img {
            width: 100%;
            height: auto;
            object-fit: cover;
            /* Mantiene el aspecto y llena el contenedor */
        }
    }

    .cardBodyContent {
        padding: 15px;
        display: flex;
        flex-direction: column;
        flex: 1;

        h1 {
            color: $subTitle;
            font-size: 1.25rem;
            margin: 10px 0;
        }

        P {
            text-align: justify;
            font-size: 1rem;
            margin: 10px 0;
        }

        .cardButton {
            margin-top: auto;

            .linkButton {
                background-color: #007BFF;
                border: none;
                color: white;
                padding: 10px 20px;
                text-align: center;
                text-decoration: none;
                display: inline-block;
                font-size: 1rem;
                margin: 10px 0;
                cursor: pointer;
                border-radius: 5px;
            }
        }
    }
}

@media (max-width: 768px) {

    .cardContent {
        flex: 1 1 100%;
        max-width: 100%;
    }
}