.registerContainer {
  min-height: calc(100vh - 165px);
  font-family: "Montserrat", sans-serif;
  margin-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  .register {
    display: flex;
    flex-direction: column;
    min-width: calc(30% - 20px);
    gap: 35px;
    .formContainer {
      display: flex;
      flex-direction: column;
      gap: 15px;
      select {
        font-family: "Montserrat", sans-serif;
        padding: 15px;
        border: none;
        color: #000;
        font-weight: bold;
        border-bottom: 2px solid #000;
        outline: none;
      }
      option {
        color: #000;
        font-weight: bold;
      }
      input {
        border: none;
        border-bottom: 2px solid #000;
        padding: 15px;
        &:focus {
          outline: none;
          border-color: rgb(19, 56, 189);
        }
      }
      .bottomForm {
        font-size: 12px;
        display: flex;
        flex-direction: column;
        gap: 12px;

        .registerMessage {
          span {
            font-size: 15px;
            font-weight: bold;
            color: rgb(248, 86, 86);
          }
        }
        span {
          color: #000;
          font-weight: bolder;
        }
        a {
          color: rgb(248, 86, 86);
          font-weight: bold;
        }
        .button {
          display: flex;
          justify-content: center;
          button {
            background-color: rgb(248, 86, 86);
            color: #fff;
            padding: 10px;
            font-size: 15px;
            transition: 0.2s ease-in-out;
            border: 2px solid rgb(248, 86, 86);
            font-weight: bold;

            border-radius: 5px;

            &:hover {
              background-color: transparent;
              border: 2px solid #000;
              color: rgb(248, 86, 86);
              cursor: pointer;
            }
          }
        }
      }

      .required {
        border-color: red !important;
      }
    }
  }
}
