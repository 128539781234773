.light {
    // background-color: hsl(0, 0%, 100%);
    // color: black;

    .main-color {
        background-color: #FFFF;
        color: black;
        // stroke: black;
        fill: black;
    }

    .background-img {
        background-image: url('./assets/backgroundImages/bg.jpg');
        background-size: cover;
        background-repeat: no-repeat;
    }

    .sub-color {
        background-color: #B5B0B0;
        stroke: black;
    }

    .bg-gradient {
        background: linear-gradient(#FFFF, #B5B0B0);
    }
    .border{
        border:1px solid white;
    }
    .border-invert{
        border:1px solid #595959;
    }
    .caption{
        color:#595959;
    }
    .icon{
        fill: black;
    }
}

.dark {
    // background-color: #595959;
    // color: white;

    .main-color {
        background-color: #595959;
        color: white;
        stroke: white;
        fill: white;

    }

    .background-img {
        background-image: url('./assets/backgroundImages/bg-dark.jpg');
        background-size: cover;
        background-repeat: no-repeat;
    }

    .sub-color {
        background-color: #404040;
        stroke: white;

    }
    .border{
        border:1px solid #595959;
    }
    .border-invert{
        border:1px solid white;

    }
    .caption{
        color: #B5B0B0;
    }

}