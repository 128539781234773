@import 'src/_variables.scss';

header {
  .navbar {
    width: 100%;
    height: 60px;
    background: rgba(19, 56, 189, 0.80); // Blue with 30% transparency
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2000;
    font-family: $fontthin;
    display: flex;
    // justify-content: space-between;
    align-items: center;
    box-shadow: 0px 3px 5px rgb(0, 0, 0, .3);

    .headerTop {
      width: 25%;
      padding-left: 20px;
      box-sizing: border-box;

      .logo {
        display: flex;
        align-items: center;
        text-decoration: none;

        img {
          width: 50px;
          height: auto;
        }

        h1 {
          margin: 0;
          font-size: 24px;
          color: #fff;
          letter-spacing: 1px;

          i {
            font-style: normal;
            font-weight: 100;
          }

          .schoolName {
            &::before {
              content: 'ctive english school';
            }
          }

          p {
            display: inline;
            font-size: 14px;
            vertical-align: super;
            color: #fff;
          }
        }
      }
    }

    .menuIcon {
      display: none;
      cursor: pointer;
      position: absolute;
      right: 20px;

      .material-icons {
        font-size: 36px;
        color: #fff;
      }
    }

    .headerBottom {
      width: 74%;
      height: 100%;

      // background-color: #fff;
      .headerBottomList {
        height: 100%;
        display: flex;
        list-style: none;
        padding: 0 10px;
        margin: 0;
        justify-content: right;
        flex-grow: 1;

        // background-color: gray;
        .colorfull {
          a {
            color: transparent !important;
            background-image: linear-gradient(to left, #fbff0e, #FBC02D, #FBC02D, #fbff0e, #fbff0e, #FBC02D);
            // background-image: linear-gradient(to left, #FF8A65, #ff0c0c, #ff0c0c,#FF8A65,#FF8A65,#ff0c0c);

            background-clip: text;
            animation: color 1s linear infinite;
            background-size: 500%;
            font-weight: bolder;
          }

          @keyframes color {
            from {
              background-position: 0% 50%;
            }

            to {
              background-position: 100% 50%;
            }
          }
        }

        .navItem {
          // width: 130px;
          height: 100%;
          position: relative;
          min-width: 130px;
          align-content: center;
          // background-color: black;
          padding: 0 5px;
          margin-left: 5px;

          .navLinks {
            height: auto;
            color: white;
            text-decoration: none;
            display: block;
            text-align: center;
            box-sizing: border-box;
            height: 100%;
            align-content: center;
          }

          .dropdown {
            width: 100%;
            display: block;
            position: absolute;
            clip-path: polygon(0 0, 0 0, 0 0, 0 0);
            -webkit-clip-path: polygon(0 0, 0 0, 0 0, 0 0); // Safari
            background: rgba(19, 56, 189, 0.9);
            top: 100%;
            left: 0;
            border-radius: 0 0 4px 4px;

            ul {
              padding: 20px 10px 10px 20px;

              li {
                list-style: none;
                margin-bottom: 8px;

                a {
                  text-decoration: none;
                  color: #ffff;
                  font-weight: 400;
                  box-sizing: border-box;

                  &:hover {
                    font-weight: 600;
                  }

                }
              }

            }
          }

          &:hover,
          &:focus {
            background-color: rgba(255, 255, 255, 0.10);
            cursor: pointer;

            .dropdown {
              -webkit-clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 0);
              /* Safari */
              clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 0);
              visibility: visible;
              /* Alternativa */
              transition: all .2s;
            }
          }
        }

      }
    }


  }
}
//iphone settings
@supports (-webkit-touch-callout: none) {
  @media screen and (max-width:1080px) {
    .navbar {
      .headerBottom {
        .headerBottomList {
          .navItem {
            .navLinks {
              display: flex !important;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }


    }
  }
}

@media screen and (max-width: 1250px) {
  .navbar {
    .headerTop {
      .logo {
        h1 {
          .schoolName {
            &::before {
              content: 'english school' !important;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1134px) {
  .navbar {
    
    .headerBottom {
      .headerBottomList {
        .navITeacher,.navISuper {
          min-width: 120px !important;
        }

      }

    }
  }
}

@media screen and (max-width: 1060px) {
  .navbar {
    .headerTop {
      .logo {
        h1 {
          i {
            display: none;
          }
        }
      }
    }
  }
}

// Responsive Styles
@media screen and (max-width: 890px) {
  header {
    .navbar {
      .menuIcon {
        display: block;
      }

      .headerBottom {
        display: none;
        position: absolute;
        flex-direction: column;
        width: 100%;
        height: 100vh; // arreglar esto
        top: 100%;
        background: rgba(19, 56, 189, 0.9); // More opaque dropdown background
        z-index: 10000;

        &.active {
          display: grid;

        }

        .headerBottomList {
          width: 100%;
          display: block;
          padding: 50px 0;
          margin: 0;

          // background-color: gray;
          .navItem {
            height: fit-content;
            position: relative;
            align-content: center;
            // background-color: black;
            padding: 5px 5px;
            margin-left: 0px;

            .navLinks {
              margin: 10px;
            }


            .dropdown {
              background: transparent;

              ul {
                li {
                  text-align: center;
                }
              }
            }

            &:hover,
            &:focus {
              .dropdown {
                position: relative;
                display: contents;
              }
            }


          }

        }
      }
    }
  }
}