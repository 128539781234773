.mainModalConfirm {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400;
    background-color: #fff;
    border: 2px solid #000;
    box-shadow: 24;
    width: 300px;
    padding: 25px;

    .containerModalConfirm {
        display: flex;
        flex-direction: column;
        font-family: "Montserrat", sans-serif;
        font-size: 15pt;
        justify-content: center;
        gap: 15px;

        .childButtons {
            display: flex;
            justify-content: space-between;

            .disabled {
                background-color: #757575;
                color: #fff;
                border: 2px solid #757575;

                &:hover {
                    background-color: #757575;
                    color: #fff;
                    border: 2px solid #757575;
                }
            }
        }
    }
}