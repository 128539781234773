@import '../../../variables';

.containerTKids {
    background-image: linear-gradient(160deg, white, #d7f4f8, #afe0e6, #45abb6);
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: row;
    min-height: calc(100vh - 120px);
    height: fit-content;
    position: relative;
    margin-top: 60px;

    .logo {
        position: absolute;
        width: 50%;
        left: 45vw;
        bottom: 2vh;
        z-index: 0;
        opacity: 50.5%;
    }

    .mediaContainer {
        z-index: 1;
        display: flex;
        justify-content: center;
        width: 100%;

        .mediaItems {
            width: 100%;
            justify-content: center;
            display: grid;
            padding: 100px 25px 100px 25px;
            grid-template-columns: 50% 50%;
            grid-template-rows: auto auto;
            gap: 15px;

            .pdfContainer {
                grid-column: 1;
                grid-row: 1 / span 2;

                .headerPdf {
                    display: flex;
                    justify-content: flex-end;
                    background-color: #f1f3f4;
                    opacity: .8;
                    border-radius: 5px;
                    padding: 5px;

                    button {
                        background-color: transparent;
                        border: none;
                        color: $lightgray;

                        &:hover {
                            cursor: pointer;
                            color: $gray;
                        }
                    }
                }

                overflow: hidden;

                iframe {
                    overflow: hidden;
                    width: 100%;
                    height: 780px;

                    /*-------------------------------------------------------------------------------------------------------*/
                    /*Scroll bar*/
                    /*-------------------------------------------------------------------------------------------------------*/
                    ::-webkit-scrollbar {
                        width: var(--scroll-size, 10px);
                        height: var(--scroll-size, 10px);
                    }

                    ::-webkit-scrollbar-track {
                        background-color: var(--scroll-track, transparent);
                        border-radius: var(--scroll-track-radius, var(--scroll-radius));
                    }

                    ::-webkit-scrollbar-thumb {
                        background-color: var(--scroll-thumb-color, grey);
                        background-image: var(--scroll-thumb, none);
                        border-radius: var(--scroll-thumb-radius, var(--scroll-radius));
                        --scroll-size: 10px;
                        --scroll-track: rgb(255 255 255 / 10%);
                        --scroll-thumb: linear-gradient(45deg, #FFFF, #8c8b8b, #5c5c5c, #8c8b8b, #FFFF);
                    }
                }


                .pdfMain {
                    position: relative;
                    width: 100%;
                    height: 90vh;
                }
            }

            .presentationContainer {
                grid-column: 2;

                .headerPPTPresentation {
                    display: flex;
                    justify-content: space-between;
                    padding: 5px;
                    background-color: #f1f3f4;
                    opacity: .8;
                    border-radius: 5px;
                    .headerImg {
                        img {
                            width: 25px;
                        }
                    }

                    button {
                        background-color: transparent;
                        border: none;
                        color: $lightgray;

                        &:hover {
                            cursor: pointer;
                            color: $gray;
                        }
                    }
                }


            }
        }
    }
}

@media screen and (max-width: 971px) {
    .containerTKids {
        .logo {
            position: absolute;
            width: 60%;
            bottom: 2vh;
            z-index: 0;
            left: 30vw;
        }

        .mediaContainer {

            .mediaItems {
                display: flex;
                flex-direction: column;
                padding-top: 50px;

                .pdfContainer {
                    grid-column: 1;
                    grid-row: 1 / span 2;

                    .headerPdf {
                        display: flex;
                        justify-content: flex-end;
                        background-color: #f1f3f4;
                        opacity: .8;
                        border-radius: 5px;
                        padding: 5px;

                        button {
                            background-color: transparent;
                            border: none;
                            color: $lightgray;

                            &:hover {
                                cursor: pointer;
                                color: $gray;
                            }
                        }
                    }

                    overflow: hidden;

                    iframe {
                        overflow: hidden;
                        width: 100%;
                        height: 500px;

                        /*-------------------------------------------------------------------------------------------------------*/
                        /*Scroll bar*/
                        /*-------------------------------------------------------------------------------------------------------*/
                        ::-webkit-scrollbar {
                            width: var(--scroll-size, 10px);
                            height: var(--scroll-size, 10px);
                        }

                        ::-webkit-scrollbar-track {
                            background-color: var(--scroll-track, transparent);
                            border-radius: var(--scroll-track-radius, var(--scroll-radius));
                        }

                        ::-webkit-scrollbar-thumb {
                            background-color: var(--scroll-thumb-color, grey);
                            background-image: var(--scroll-thumb, none);
                            border-radius: var(--scroll-thumb-radius, var(--scroll-radius));
                            --scroll-size: 10px;
                            --scroll-track: rgb(255 255 255 / 10%);
                            --scroll-thumb: linear-gradient(45deg, #FFFF, #8c8b8b, #5c5c5c, #8c8b8b, #FFFF);
                        }
                    }


                }
            }
        }
    }


}

@media screen and (max-width: 320px) {}