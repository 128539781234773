.table-container {
    width: 100%;
    overflow-x: auto;
    margin: 20px 0;
    .custom-table {
        width: 100%;
        border-collapse: collapse;
        text-align: left;
        vertical-align: middle;
    
        th, td {
            padding: 10px;
            border-width: 4px;
        }
    
        th {
            font-weight: normal;
        }
    
        tr:hover {
            filter: brightness(.95);
        }
        /*Table columns*/
        .tableCType1 {
            width: 5%;
            min-width: 20px;
        }

        .tableCtype2 {
            width: 10%;
            min-width: 30px;
        }

        .tableCtype3 {
            width: 13%;
            min-width: 50px;
        }

        .tableCtype4 {
            width:15%;
            min-width: 60px;
        }

        .tableCtype5 {
            width:25%;
            min-width: 65px;
        }

        .tableCtype6 {
            width: 30%;
            min-width: 80px;
        }

        .available-schedule{
            width: 100%;
            background-color: gray;
            color: #FFF;
            td {
                border-width: 0px;
            }
        }
    }
}


