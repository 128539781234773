@import '../../variables';

.kids_side_left {
    width: 15%;
    background-color: $gray;
    padding: 2% 1% 2% 1%;
    // height: fit-content;
    min-height: 95vh;
    .kids_nav {
        padding: 0%;

        .kids_level1,
        .kids_level3,
        .kids_level5,
        .kids_level7 {
            .svg_box {
                .back_arrow {
                    left: 3%;
                }
            }
        }

        .kids_level2,
        .kids_level4,
        .kids_level6 {
            .svg_box {
                .back_arrow {
                    right: 3%;
                    left: auto;
                }
            }
        }

        .kids_level1,
        .kids_level2,
        .kids_level3,
        .kids_level4,
        .kids_level5,
        .kids_level6,
        .kids_level7 {
            width: 100%;
            box-sizing: border-box;
            position: relative;
            list-style: none;

            .svg_box {
                display: flex;
                position: relative;
                color: white;
                align-items: center;
                gap: .6rem;
                transition: .2s all;

                .back_arrow {
                    display: var(--display, none);
                    position: absolute;
                    height: 30%;
                    bottom: 5%;
                }

                svg {
                    width: 100%;
                    height: 12vh;

                    :nth-child(1) {
                        filter: drop-shadow(4px 4px 2px hsla(0, 0%, 0%, 0.5));
                    }
                }

                .svg_text {
                    position: absolute;
                    display: flex;
                    height: 90%;
                    width: fit-content;
                    z-index: 1000;
                    top: 0;
                    align-items: center;

                    p {
                        position: relative;
                        display: flex;
                        color: white;
                        font-family: $fontkids;
                        font-size: 2vw;
                        margin: 0%;
                    }
                }

                img {
                    height: 10vh;
                    position: absolute;
                }

                .svg_check {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    opacity: 0;
                    cursor: pointer;
                    z-index: 1000;
                }

            }

            .dropdown_unit_content {
                display: grid;
                grid-template-rows: var(--rows, 0fr)0fr;
                transition: .3s grid-template-rows;
                justify-content: center;
                margin-top: 5px;
                .units_dropdown_ul {
                    overflow: hidden;
                    padding-left: 0.5vw;
                    display: flex;
                    flex-wrap: wrap;
                    width: fit-content;

                    .units_dropdown_li {
                        background-color: gray;
                        border-radius: 50%;
                        margin: 0.1vw;
                        padding: 1%;
                        text-align: center;
                        color: white;
                        list-style: none;
                        height: 4vh;
                        aspect-ratio: 1 / 1;
                        text-wrap: nowrap;
                        // box-sizing: border-box;

                        &:hover {
                            background-color: $lightgray;
                            cursor: pointer;
                        }

                    }
                }

                .units_dropdown_li.selected {
                    background-color: gray !important;
                }
            }

            &:hover {
                svg {
                    transform: scale(1.05);
                    transition: all .3s;
                    cursor: pointer;

                    :nth-child(1) {
                        filter: drop-shadow(6px 0 2px hsla(0, 0%, 0%, 0.2));
                    }
                }
            }

            &:has(:checked) {
                --rows: 1fr;
                --display: flex;
                // --rotate: rotate(180deg);
            }


        }

        .disabled {
            // display: none !important;
            transform: scaleY(0);
            height: 0;
            padding: 0;
            margin: 0;
        }

        .kids_level1 {
            .dropdown_unit_content {
                .units_dropdown_ul {
                    .units_dropdown_li {
                        background-color: $smile1;
                    }
                }
            }

            svg {
                :nth-child(1) {
                    fill: $smile1;
                }

                :nth-child(2) {
                    fill: $smile1light;
                }
            }

            .svg_text {
                p {
                    -webkit-text-stroke: 2px $smile1;
                    font-weight: bold;
                    left: 8%;
                }
            }

            img {
                right: 0;
            }

        }

        .kids_level2 {
            .dropdown_unit_content {
                .units_dropdown_ul {
                    .units_dropdown_li {
                        background-color: $smile2;
                    }
                }
            }

            svg {
                :nth-child(1) {
                    fill: $smile2;
                }

                :nth-child(2) {
                    fill: $smile2light;
                }
            }

            .svg_text {
                right: 5%;

                p {
                    -webkit-text-stroke: 2px $smile2;
                }
            }

            img {
                left: 0%;
            }

        }

        .kids_level3 {
            .dropdown_unit_content {
                .units_dropdown_ul {
                    .units_dropdown_li {
                        background-color: $smile3;
                    }
                }
            }

            svg {
                :nth-child(1) {
                    fill: $smile3;
                }

                :nth-child(2) {
                    fill: $smile3light;
                }
            }

            .svg_text {
                left: 5%;

                p {
                    -webkit-text-stroke: 2px $smile3;
                }
            }

            img {
                right: 0%;
            }

        }

        .kids_level4 {
            .dropdown_unit_content {
                .units_dropdown_ul {
                    .units_dropdown_li {
                        background-color: $smile4;
                    }
                }
            }

            svg {
                :nth-child(1) {
                    fill: $smile4;
                }

                :nth-child(2) {
                    fill: $smile4light;
                }
            }

            .svg_text {
                right: 5%;

                p {
                    -webkit-text-stroke: 2px $smile4;
                }
            }

            img {
                left: 0;
            }

        }

        .kids_level5 {
            .dropdown_unit_content {
                .units_dropdown_ul {
                    .units_dropdown_li {
                        background-color: $smile5;
                    }
                }
            }

            svg {
                :nth-child(1) {
                    fill: $smile5;
                }

                :nth-child(2) {
                    fill: $smile5light;
                }
            }

            .svg_text {
                left: 5%;

                p {
                    -webkit-text-stroke: 2px $smile5;
                }
            }

            img {
                right: 0;
            }

        }

        .kids_level6 {
            .dropdown_unit_content {
                .units_dropdown_ul {
                    .units_dropdown_li {
                        background-color: $smile6darker;
                    }
                }
            }

            svg {
                :nth-child(1) {
                    fill: $smile6;
                }

                :nth-child(2) {
                    fill: $smile6light;
                }
            }

            .svg_text {
                right: 5%;

                p {
                    -webkit-text-stroke: 2px $smile6;
                }
            }

            img {
                left: 0;
            }

        }

        .kids_level7 {
            .dropdown_unit_content {
                .units_dropdown_ul {
                    .units_dropdown_li {
                        background-color: $mega;
                    }
                }
            }

            svg {
                :nth-child(1) {
                    fill: $mega;
                }

                :nth-child(2) {
                    fill: $megalight;
                }
            }

            .svg_text {
                left: 5%;

                p {
                    -webkit-text-stroke: 2px $mega;
                }
            }

            img {
                right: 0;
                bottom: 10%;
            }

        }
    }

}



//RIGHT MENU FOR MATERIAL TYPE
//-------------------------------------------
.kids_side_right {
    // background-color: $lightergray;
    width: 20%;
    min-width: 280px;
    position: absolute;
    right: 0;
    z-index: 2;

    .material_menu {
        display: flex;
        // background-color: #b9d0e2;
        padding: 10px 0px 5px 5px;
        position: relative;
        gap:1vw;

        //CIRCLE BUTTONS
        .submenu_disabled {

            .material_section1,
            .material_section2,
            .material_section3,
            .material_section4 {
                display: flex;
                width: 80%;
                aspect-ratio: 1 / 1;
                border-radius: 50%;
                box-sizing: border-box;
                margin-bottom: 10px;
                box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
                z-index: 10000;

                img {
                    width: 80%;
                }

                background-color: $gray;

                .material_book {
                    width: fit-content;
                    padding: 10%;
                    position: absolute;
                    justify-content: center;
                    align-items: center;
                    display: none;
                    right: 0%;
                    top: 0%;
                    left: -150px;

                    li {

                        width: max-content;
                        font-size: $title;
                        font-family: $fontkids;
                        list-style-type: none;
                        height: fit-content;
                        cursor: pointer;
                        margin-bottom: 5px;
                        border-radius: 20px;
                        padding: 2px 5px 2px 5px;
                        // transform-origin: -50px;
                        // transition: all 0.5s;
                        // transform: rotate(calc(360deg / 8 * var(--i)));
                    }
                }
            }
        }

        .submenu {
            
            .material_section,
            .material_section1,
            .material_section2,
            .material_section3,
            .material_section4 {
                display: flex;
                width: 80%;
                aspect-ratio: 1 / 1;
                border-radius: 50%;
                box-sizing: border-box;
                margin-bottom: 10px;
                box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
                z-index: 10000;

                img {
                    width: 80%;
                }

                &:hover {
                    background-color: $sistemblue;
                    border-radius: 50%;
                    transform: scale(1.2);
                    cursor: pointer;

                    .material_book {
                        display: block;
                    }
                }

                .material_book {
                    width: fit-content;
                    padding: 10%;
                    position: absolute;
                    justify-content: end;
                    align-items: center;
                    display: none;
                    right: 0%;
                    top: 100%;
                    // left: -150px;


                    li {

                        width: max-content;
                        font-size: $caption;
                        font-family: $fontkids;
                        list-style-type: none;
                        height: fit-content;
                        cursor: pointer;
                        margin-bottom: 5px;
                        border-radius: 20px;
                        padding: 2px 5px 2px 5px;
                        // transform-origin: -50px;
                        // transition: all 0.5s;
                        // transform: rotate(calc(360deg / 8 * var(--i)));
                    }
                }

            }
        }

        .sub1 {
            .material_book {
                li {

                    background-color: $lightgreen;
                }
            }
        }

        .sub2 {
            .material_book {
                li {
                    background-color: $lightyellow
                }
            }
        }

        .sub3 {
            .material_book {
                li {
                    background-color: $lightbue
                }
            }
        }

        .sub4 {
            .material_book {
                li {
                    background-color: $lightred
                }
            }
        }

        .material_section1 {
            background-color: $green;
        }

        .material_section2 {
            background-color: $yellow;
        }

        .material_section3 {
            background-color: $lightbue;
        }

        .material_section4 {
            background-color: $activered;
        }
    }


}


@media screen and (max-width:1050px) and (min-width: 651px) {

    .kids_side_left {
        .kids_nav {

            .kids_level1,
            .kids_level2,
            .kids_level3,
            .kids_level4,
            .kids_level5,
            .kids_level6 {
                .svg_text {
                    width: 30% !important;
                    overflow: hidden;
                    position: relative;
                    justify-content: right;

                    p {
                        position: absolute;
                        font-size: 6vw !important;
                        text-wrap: nowrap;

                    }

                }
            }
            .kids_level1,
            .kids_level3,
            .kids_level5 {
                .svg_text {
                    left:10%

                }
            }

            .kids_level7 {
                .svg_text {
                    width: 47% !important;
                    overflow: hidden;
                    position: relative;
                    justify-content: left;

                    p {
                        position: absolute;
                        font-size: 6vw !important;
                        text-wrap: nowrap;
                    }

                }
            }
        }
    }

    .kids_side_right {
        width: 22vw;
        min-width: 190px;

        .material_menu {
            .submenu {

                .material_section1,
                .material_section2,
                .material_section3,
                .material_section4 {
                    width: 100%;

                    .material_book {
                        li {
                            font-size: $normal;
                        }
                    }

                }

                .material_section1,
                .material_section2,
                .material_section3,
                .material_section4 {
                    .material_book {
                        transform: translateX(20%);
                    }
                }
            }
        }
    }

}

@media screen and (max-width:650px) and (min-width:401px) {

    .kids_side_left {
        .kids_nav {
            li {
                .svg_box {
                    height: 11vh !important;
                }

                .svg_text {
                    p {
                        filter: opacity(0);
                    }
                }
            }
        }
    }

    .kids_doc_frame {
        img {
            height: 60%;
        }
    }

    .kids_side_right {
        width: 28vw;
        min-width: 150px;

        .material_menu {

            //CIRCLE BUTTONS
            .submenu {

                .material_section1,
                .material_section2,
                .material_section3,
                .material_section4 {
                    display: flex;
                    width: 100%;

                    img {
                        width: 100%;
                    }

                    .material_book {
                        left: -60px;

                        li {
                            font-size: $normal;
                        }
                    }

                }
            }
        }
    }

}

@media screen and (max-width:400px) {

    .kids_side_left {
        .kids_nav {
            li {
                height: 10vh;

                .svg_box {
                    // padding: 0 5%;

                    .svg_text {
                        width: 65% !important;
                        overflow: hidden;
                        position: absolute !important;
                        justify-content: right;
                        left: 0;
                        margin-left: 20%;

                        p {
                            position: absolute;
                            font-size: 12vw !important;
                            text-wrap: nowrap;
                            filter: opacity(1);
                        }
                    }
                }

                .svg_img {
                    display: none;
                }
            }

            :nth-child(7) {
                .svg_text {
                    p {
                        visibility: hidden;

                        &::after {
                            content: " 7";
                            visibility: visible;
                        }
                    }

                }
            }
        }
    }

    .kids_doc_frame {
        img {
            height: 50%;
        }
    }

    .kids_side_right {
        width: 55vw;
        min-width: 150px;

        .material_menu {
            display: flex;

            //CIRCLE BUTTONS
            .submenu {

                .material_section1,
                .material_section2,
                .material_section3,
                .material_section4 {
                    display: flex;
                    width: 80%;
                    min-width: 25px;

                    img {
                        width: 100%;
                    }

                    .material_book {
                        left: 0;
                        top: 0;
                        bottom: 0;

                        li {
                            font-size: $paragraph;
                            padding: 2px 10px 0px 10px;
                        }
                    }

                }

                .material_section1,
                .material_section2 {
                    .material_book {
                        transform: translateY(100%) translateX(-50%);
                    }

                }

                .material_section3,
                .material_section4 {
                    .material_book {
                        transform: translateY(100%) translateX(-75%);
                    }

                }
            }
        }
    }

}