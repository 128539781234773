.mapboxgl-map{

    .mapboxgl-control-container{
        display: none !important;
    }
}
.mapboxgl-map > div:nth-child(4){
    display: none;
}

// .mapboxgl-marker{
//     transform: none !important;
// }