.input-container-animated {
    position: relative;
    width: 100%;
    padding: 0%;
    box-sizing: border-box;
    font-family: Calibri, sans-serif;
    display: flex;
    align-content: center;
    gap: 5px;
    margin-top: 15px;
    >.txt-inp {
        width: 100%;
        position: relative;
        // color: #B5B0B0;
        padding: 15px;
        border-radius: 5px;
        outline: none;
        border: 1px solid #B5B0B0;
        box-sizing: border-box;
        background-color: transparent;
        // font-size: 14pt;

        &:focus {
            border-color: #007bff;
            outline: none;
        }
    }

    .checkbox {
        height: 20px;
        width: 20px;
    }

    .input-label {
        font-size: 14pt;
        width: fit-content;
        position: absolute;
        // color: #B5B0B0;
        letter-spacing: 1px;
        top: 25% !important; // hice este cambiio
        left: 2%;
        pointer-events: none;
        transition: all 0.4s ease-out;
        background-color: transparent;
    }

    /*input:focus + label makes the label do the transition when the input is on focus, and the input:not()*/
    /* in for the exception of the text in the input has been writed so this prevent the label retur to the original position */
    .txt-inp:focus+label,
    .txt-inp:not(:placeholder-shown)+label {
        position: absolute;
        opacity: 100%;
        transform: translateY(-110%);
        font-size: 10pt;
        height: 15px;
        top: 0% !important;
        left: 15px;
        color: #B5B0B0;
    }

    .txt-inp:focus+span,
    .txt-inp:not(:placeholder-shown)+span {
        opacity: 100%;
        transform: translateY(-50%);
        font-size: 10pt;
        height: 15px;
        top: 0% !important;
        left: 15px;
        color: #B5B0B0;
    }

}
