.modal-overlay {

    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    .modal-content {
        position: relative;
        width: 90vw;
        height: 90vh;
        border-radius: 7px;
        padding: 20px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        font-size: 13.5pt;
        font-weight: lighter;
        letter-spacing: 0.6pt;
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        max-width: 500px;
        max-height: 450px;
        h2{
            font-size: 24pt;
            font-weight: 400;
            font-family: calibri;
        }

        .svg-container {
            padding: 10px;
            width: 85%;
            height: 150px;
            position: relative;
            border-radius:5px;
        }
        .buttons-container{
            margin-top: 10px;
            height: 50px;
            display: flex;
            gap:5%;
        }
        .message_container{
            width: 50%;
            text-align: center;
            font-family: calibri;
        }
    }
    
    .close-button {
        position: absolute;
        top: 10px;
        right: 10px;
        background: transparent;
        border: none;
        font-size: 20px;
        cursor: pointer;
    }
    

}


