.custom-select {
    position: relative;
    width: 100%;
    margin-top: 10px;
    select {
        width: 100%;
        position: relative;
        color: #757575;
        padding: 10px;
        border-radius: 5px;
        outline: none;
        border: 1px solid #B5B0B0;
        box-sizing: border-box;
        background: transparent;
        font-family: calibri;
        font-size: 14pt;
        letter-spacing: 1px;
        cursor: pointer;


        &:focus {
            border-color: #007bff;
            outline: none;
        }

        option {
            color: #B5B0B0 !important;
        }
    }
}