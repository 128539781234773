.photo-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(min(300px, 100%), 350px));
    gap: 5vw;
    justify-content: center;
    overflow: hidden;
    margin: 25px;


    img {
        width: 100%;
        height: auto;
        box-sizing: border-box;
    }

    @media (min-width: 1200px) {
        grid-template-columns: repeat(4, 1fr); // 4 columnas
    }

    @media (min-width: 900px) and (max-width: 1199px) {
        grid-template-columns: repeat(4, 1fr); // 4 columnas

    }

    @media (min-width: 600px) and (max-width: 899px) {
        grid-template-columns: repeat(2, 1fr); // 2 columnas

    }

    @media (max-width: 425px) {
        grid-template-columns: repeat(auto-fill, minmax(min(300px, 100%), 350px));
    }
}