/* CustomDatePicker.css */
@import '../../variables';

.custom-datepicker {
    position: relative;
    font-family: $fontquicksand !important;
    width: 100%;
    align-items: center;
    
    .calendar-icon {
        position: absolute;
        right: 2%;
        top: 10%;
    }
    
    input[type="text"] {
        box-sizing: border-box;
        width: 100%;
        padding: 8px 15px 8px 8px;
        font-size: $caption;
        border: 1px solid #ccc;
        border-radius: 4px;
        cursor: pointer;
        color: $gray;
        letter-spacing: 1px;
    }

    .calendar-container {
        position: absolute;
        // width:100%;
        top: 100%;
        left: 0;
        display: none;
        background-color: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        padding: 10px;
        z-index: 100000;
        border-radius: 5px;
        background-color: gray;

        .selected-info {
            font-size: $normal;
            letter-spacing: 0px;
        }

        .calendar-header {
            display: block;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
            font-size: $caption;
            color: white;

            div {
                width: 100%;
                display: flex;

                .prev-month,
                .next-month {
                    width: 15%;
                    margin-left: 5%;
                    cursor: pointer;
                    font-size: $paragraph;
                    padding: 2px 5px 2px 5px;
                }

                .current-month {
                    width: 60%;
                    text-align: center;
                }
            }
        }

        table {
            width: 80%;
            border-collapse: collapse;

            th,
            td {
                padding: 5px;
                text-align: center;
                font-size: $paragraph;
                color: white;
            }

            th {
                font-weight: 100;
                border-bottom: 1px solid $lightgray;
                color: white;

            }

            td {
                cursor: pointer;
            }

            .day {
                position: relative;
            }

            .out-of-range {
                //empty cell
                height: 0px;
                padding: 0%;
            }

            .selected {
                background-color: $lightergray !important;
                color: $gray !important;
                border-radius: 50%;
            }

            .date:hover {

                background-color: $lightergray;
                color: $gray;
                border-radius: 50%;
            }

            .selected-range {
                background-color: $lightgray;
                color: #fff;
            }

            .selected-week {
                background-color: $lightergray;
                color: $gray;

                &:first-child {
                    border-radius: 50% 0px 0px 50%;
                }

                &:last-child {
                    border-radius: 0px 50% 50% 0px;
                }
            }

            tr:hover .week {
                background-color: $lightgray;
                color: #fff;

                &:first-child {
                    border-radius: 50% 0px 0px 50%;
                }

                &:last-child {
                    border-radius: 0px 50% 50% 0px;
                }
            }

            .todays-date {
                background-color: $sistemred;
                color: #fff;
                font-weight: bold;
                border-radius: 50%;
            }
        }
    }
}



@media only screen and (max-width: 1300px) {
    .custom-datepicker {
        
        input[type="text"] {
            font-size: $normal;
        }
    }
}

@media only screen and (max-width: 800px) {
    input[type="text"] {
        font-size: $paragraph !important;
        letter-spacing: normal !important;
        min-width: 150px !important;
    }
}