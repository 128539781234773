@import 'src/_variables.scss';

.cContainer {
    display: flex;
    flex-direction: column;
    font-family: $fontthin;
    font-size: $normalInfo;
    color: #fff;

    .cPresentacionContainer {
        display: flex;
        min-height: 500px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 0px;
        justify-content: flex-end;
        align-items: flex-end;

        h1 {
            padding-right: 25px;
            font-size: 80pt;
            font-weight: normal;
        }
    }

    .contactOnlineContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #1338BD;

        .activeContent {
            width: 35%;
            padding: 25px;

            .imgContainer {
                display: flex;
                justify-content: center;

                img {
                    width: 350px;
                }
            }

            .bodyContainer {
                font-weight: normal;
                padding: 25px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .locations {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 25px;
        gap: 25px;

        .bugambilias,
        .estrella {
            width: 50%;
            display: flex;
            flex-direction: column;
            text-align: center;
            gap: 25px;
            padding: 25px;
            font-weight: normal;
            box-sizing: border-box;
        }

        .estrella {
            background-color: #5A7BEE;
        }

        .bugambilias {
            background-color: #F56561;
        }
    }

    .emailForm {
        background-color: #F5F5F5;
        padding: 25px;
        border-radius: 10px;
        color: #333;
        box-sizing: border-box;
        width: 100%;

        .titleForm {
            text-align: center;
            margin-bottom: 20px;

            h1 {
                font-size: 30pt;
                color: #1338BD;
            }
        }

        .inputs {
            display: flex;
            flex-direction: column;
            gap: 15px;

            input,
            textarea {
                width: 100%;
                padding: 15px;
                border: 1px solid #ccc;
                border-radius: 5px;
                font-size: 14pt;
                box-sizing: border-box;
            }

            textarea {
                resize: vertical;
                height: 150px;
            }

            .containerStatusMessage {
                text-align: center;
                margin-top: 10px;

                .statusMessage {
                    color: #f56561;
                    font-size: 12pt;
                }
            }
        }

        .cButton {
            background-color: #1338BD;
            color: #fff;
            border: none;
            padding: 15px;
            font-size: 16pt;
            border-radius: 5px;
            cursor: pointer;
            width: 100%;
            text-align: center;
            margin-top: 20px;
            transition: background-color 0.3s;

            &:hover {
                background-color: #0f2a8a;
            }
        }
    }



}

// Media Queries
@media (max-width: 1200px) {
    .cContainer {

        .cPresentacionContainer {
            h1 {
                padding-right: 20px;
            }
        }

        .contactOnlineContainer .activeContent {
            width: 50%;
            padding: 20px;

            .imgContainer img {
                width: 300px;
            }

            .bodyContainer {
                padding: 20px;
            }
        }

        .locations {
            flex-direction: column;
            padding: 20px;
            gap: 20px;

            .bugambilias,
            .estrella {
                width: 100%;
                padding: 15px;
            }
        }

        .emailForm {
            padding: 20px;

            .titleForm h1 {
                font-size: 25pt;
            }

            .inputs input,
            .inputs textarea {
                font-size: 12pt;
                padding: 10px;
            }

            .cButton {
                font-size: 14pt;
                padding: 12px;
            }
        }
    }
}

@media (max-width: 768px) {
    .cContainer {
        font-size: 1.3rem !important;

        .cPresentacionContainer {
            min-height: 250px;

            h1 {
                padding-right: 15px;
                font-size: 4rem;


            }
        }

        .contactOnlineContainer .activeContent {
            width: 70%;
            padding: 15px;

            .imgContainer img {
                width: 250px;
            }

            .bodyContainer {
                padding: 15px;
            }
        }

        .locations {
            padding: 15px;

            .bugambilias,
            .estrella {
                padding: 15px;
            }
        }

        .emailForm {
            padding: 20px;

            .titleForm h1 {
                font-size: 20pt;
            }

            .inputs input,
            .inputs textarea {
                font-size: 12pt;
                padding: 10px;
            }

            .cButton {
                font-size: 14pt;
                padding: 12px;
            }
        }
    }
}

@media (max-width: 480px) {
    .cContainer {

        .cPresentacionContainer {
            h1 {
                padding-right: 10px;
                font-size: 3rem;
            }
        }

        .contactOnlineContainer .activeContent {
            width: 100%;
            padding: 5px 0 5px 0;


            .imgContainer img {
                width: 200px;
            }

            .bodyContainer {
                padding: 10px;
            }
        }

        .locations {
            padding: 10px;

            .bugambilias,
            .estrella {
                padding: 10px;
            }
        }

        .emailForm {
            padding: 15px;

        }
    }
}