@import 'src/_variables.scss';

.metodo {
    width: 100%;
    justify-content: center;
    font-size: $normalInfo;
    font-family: $fontthin;

    .oft_header {
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        color: white;
        font-weight: 300;
        height: 500px;
        .oft_header_img {
            position: relative;
            width: 100%;

            .img1 {
                z-index: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            div {
                position: absolute;
                width: 100%;
                height: 99%;
                background-color: rgba(130, 203, 255, 0.25);
                top: 0;
            }

            .img2 {
                width: 100%;
                height: 160px;
                position: absolute;
                bottom: .5%;
                right: 0;
            }
        }

        .oft_header_text {
            position: absolute;
            top: 15%;
            left: 5%;
            margin: 0;
            font-weight: normal;
            color: #1338BD;
        }

        .oft_header_title1 {
            position: absolute;
            font-size: 65pt;
            right: 5%;
            bottom: 13%;
            margin: 0;
            color: #D8130E;
            font-weight: normal;

        }

        .oft_header_title2 {
            position: absolute;
            font-size: 80pt;
            right: 5%;
            bottom: 0%;
            margin: 0;
            font-weight: normal;

        }
    }

    .paragraph {
        position: relative;
        text-align: center;
        width: 80%;
        margin-left: 10%;
        padding: 25px 0px 25px 0px;
        .caption1 {
            font-weight: 100;
            margin: 0;
            color: #1338BD;
        }

        .caption2 {
            font-weight: 100;
            margin: 0;
            color: #1338BD;

        }

        span {
            margin-bottom: 50px;
        }
    }

    .paragraph2 {
        position: relative;
        text-align: center;
        width: 80%;
        margin-left: 10%;
        margin-bottom: 100px;

        img {
            width: 100%;
        }

        .text {
            position: absolute;
            width: 80%;
            bottom: 5%;
            left: 10%;
            background-color: rgba(255, 255, 255, 0.8);
            padding: 5px;

            .caption1 {
                font-weight: 100;
                margin: 0;
                color: #1338BD;
            }

            .caption2 {
                font-weight: 100;
                margin: 0;
                color: #1338BD;

            }

            span {
                margin-bottom: 50px;
            }
        }
    }

    img {
        width: 100vw;
    }
}

@media screen and (max-width:900px) {

    .metodo {
    font-size: 1.3rem !important;
        .oft_header {
            height: 250px;

            .oft_header_img {
                position: relative;
                width: 100%;

                .img1 {
                    z-index: 0;
                    width: 100%;
                    height: 100%
                }

                div {
                    position: absolute;
                    width: 100%;
                    height: 99%;
                    background-color: rgba(130, 203, 255, 0.25);
                    top: 0;
                }

                .img2 {
                    width: 100%;
                    height: 160px;
                    position: absolute;
                    bottom: .5%;
                    right: 0;
                }
            }
            .oft_header_text {
                top: 25%;
                
            }
            .oft_header_title1 {
            font-size: 1.8em;
            bottom: 16%;
                
            }
            
            .oft_header_title2 {
                font-size: 2.5rem;
                
            }


        }

        .paragraph {

            span {
                margin-bottom: 50px;
            }
        }

        .paragraph2 {
            display: flex;
            align-items: center;
            flex-direction: column;
            gap: 15px;
            .text {
                position: relative;
                left: 0;
                width: 100%;
                height: fit-content;
                background-color: #1338bd3c;

                .caption2 {
                    font-weight: 100;
                    margin: 0;
                    color: #1338BD;

                }

            }
        }
    }
}
