.modalConfirmContainer {
  display: flex;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
  font-size: 15pt;
  justify-content: center;
  gap: 15px;
  .childButtons {
    display: flex;
    justify-content: space-between;
    button {
      background-color: rgb(248, 86, 86);
      color: #fff;
      padding: 5px 20px;
      font-size: 15px;
      transition: 0.2s ease-in-out;
      border: 2px solid rgb(248, 86, 86);
      font-weight: bold;

      border-radius: 5px;

      &:hover {
        background-color: transparent;
        border: 2px solid #000;
        color: rgb(248, 86, 86);
        cursor: pointer;
      }
    }
    .disabled {
      background-color: #757575;
      color: #fff;
      border: 2px solid #757575;
      &:hover {
        background-color: #757575;
        color: #fff;
        border: 2px solid #757575;
      }
    }
  }
}
